.user-heading-box .user-heading{
    font-family: 'Poppins-Medium';
    font-size: 18px;
    color: #240736;
}


.presale-userlist{
    padding: 0px 10px ;
}

.presale-userlist .user-heading-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 10px 10px 10px;

}

.user-list-table-container {
    border-radius: 16px;
    border: 1px solid lightgray;
    /* border-bottom: transparent; */

}
.user-list-table-container .user-list-top-table{
    overflow: scroll;
}


.user-list-top-table .private-pre-table-head tr th {
    font-size: 13px;
    font-family: 'Poppins-Regular';
    color: #ffff;
    /* background-color: #3D117C; */
    padding: 10px 6px;
    
  }

  .user-list-top-table tbody tr td {
    padding: 8px;
    font-family: 'Poppins-Regular';
    font-size: 12px;

  }
  .user-list-top-table .private-pre-table-head tr {
    background-image: linear-gradient(to right, #00ee92, #d91ffb);
  }


.user-list-top-table tr th:nth-child(1) {
    border-top-left-radius: 16px;
  }
  
  .user-list-top-table tr th:nth-child(9) {
    border-top-right-radius: 16px;
  }
  
  .user-list-top-table tr th:nth-child(1),
  .user-list-top-table tr td:nth-child(1) {
    width: 5%;
  }
  .user-list-top-table tr th:nth-child(2),
  .user-list-top-table tr td:nth-child(2) {
    width: 15%;

  }

  
  .dash-body-cell-box {
    border: 1px solid lightgray;
    height: 38px;
    border-radius: 6px;
  }

  
  .user-list-top-table tr th:nth-child(3),
  .user-list-top-table tr td:nth-child(3) {
    width: 11%;
    text-transform: capitalize;
  }
  .user-list-top-table tr th:nth-child(4),
  .user-list-top-table tr td:nth-child(4) {
    width: 5%;
    text-transform: capitalize;
  }
  


.user-list-table-container {
    max-height: 440px; 
    height: 440px; 
    overflow-y: auto;  
  }
  .user-list-table-container::-webkit-scrollbar {
    display: none;
  }

  .user-profile-box .user-profile{
    height: 32px;
    width: 32px;
    border-radius: 50px;
  }


 .user-search-input .MuiInputBase-formControl input,
  .user-search-input
  .MuiInputBase-formControl
  input:focus-visible {
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 5.5px 14px;
  font-family: 'Poppins-Regular';
  font-size: 12px;
}
