.edit-profile-content .social-form-box{
    margin-top: 35px;
}


.form-group-prof .profile-input-field .MuiInputBase-multiline{
    border: 1px solid lightgray;
    border-radius: 4px;
    color: gray;
    font-size: 13px;
    font-family: "Poppins-regular";
  }