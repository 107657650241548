body {
 
}

.login-section {
  background-image: url("../../../../assets/images/svg/login-bg.svg");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  width: 100%;
  background-color: #240736;
}

.admin-main-bg::after {
}

.login-section:before {
  background-image: radial-gradient(#cf34f9, #cf34f9);
  filter: blur(356px);
  right: 500px;
  top: -18%;
}

.login-section:after,
.login-section:before {
  content: "";
  height: 330px;
  position: absolute;
  width: 330px;
  /* z-index: -1; */
}

.login-section:after {
  background-image: radial-gradient(#00eb94, #00eb94);
  filter: blur(280px);
  left: 4%;
  top: 28%;
}
/* .admin-main .admin-main-bg:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
} */

.admin-main .auth-main {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  gap: 30px;
  width: 100%;
  position: relative;
  z-index: 1;
}

.admin-main .auth-main .auth-left {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  padding: 50px 15px;
}

.admin-main .auth-main .auth-left .company-logo {
  width: 100%;
  max-width: 300px;
  height: auto;
}

.admin-main .auth-main .auth-right {
  width: 100%;
  overflow: auto;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 50px 15px;
}

.admin-main .auth-main .auth-common-box {
  padding: 20px;
  border-radius: var(--radius6);
  box-shadow: var(--shadow-sm);
  background-color: #fff;
  width: 100%;
  max-width: 390px;
  background: rgb(35 35 35 / 47%);
  box-shadow: -1px 4px 28px 0px rgba(0, 0, 0, 0.334);
}

.admin-main .auth-main .auth-header {
  margin-bottom: 16px;
}

.admin-main .auth-main .auth-header .auth-title {
  font-size: 28px;
  line-height: 30px;
  font-family: "poppins-semibold";
  margin-bottom: 16px;
  text-align: center;
  color: var(--white);
}

.admin-main .auth-main .auth-header .auth-title.auth-logo-title {
  font-size: 24px;
}

.admin-main .auth-main .auth-header .auth-subtitle {
  color: #989898;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Poppins-Regular';
}

.admin-main .auth-link {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  line-height: 18px;
  font-family: "poppins-medium";
  color: white;
}

.admin-main .auth-link.forgot-password {
  justify-content: flex-start;
}

.admin-main .auth-link.forgot-password p {
  color: #fff;
}

.admin-main .auth-btn-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.admin-main .auth-btn-box button {
  background-color: var(--primary);
  margin: 0 auto;
}

.admin-main .form-group .form-input.otp-input-box {
  justify-content: space-between;
  gap: 15px;
}

.admin-main .form-group .form-input.otp-input-box input {
  width: 100%;
  border: 2px solid var(--secondary);
  text-align: center;
  font-family: "poppins-regular";
}

.admin-main .form-group .form-input.otp-input-box input:focus,
.admin-main .form-group .form-input.otp-input-box input:focus-visible {
  border: 2px solid var(--primary);
  outline: none;
}

.admin-main .btn.btn-primary {
  background-color: var(--primary);
  color: var(--white);
  min-width: unset;
  border-radius: var(--radius6);
  font-size: 14px;
  line-height: 18px;
  font-family: "poppins-semibold";
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid transparent;
  padding: 10px 20px;
}

.admin-main .btn.btn-secondary {
  background-color: transparent;
  color: var(--primary);
  min-width: unset;
  font-size: 14px;
  line-height: 18px;
  font-family: "poppins-semibold";
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid var(--primary);
  padding: 10px 20px;
}

.admin-main .btn {
  padding: 0;
  min-width: unset;
}

.form-input ::after,
.form-input ::before {
  border-bottom: transparent !important;
}

.login-password .MuiFilledInput-underline {
  border: 1px solid #fff;
}

.password-icon svg {
  fill: #fff;
}

.custom-btn-login{
    width: 150px;
    height: 40px;
}

.admin-main .form-group .form-lable {
  color: #989898;
  font-size: 14px;
  line-height: 18px;
  font-family: "poppins-semibold";
  margin-bottom: 6px;
  margin-top: 0;
}

.admin-main .form-group .form-input input {
  /* background-color: #fff; */
  border-radius: 0;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 15px;
  color: #fff;
}

.admin-main .form-group .form-input.login input {
  /* background-color: #fff; */
  border-radius: 0;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 15px;
  color: #fff;
  border: 1px solid #ffff;
}

.admin-main
  .form-group
  .form-input.login
  .css-2y464i-MuiInputBase-root-MuiFilledInput-root:before,
.admin-main
  .form-group
  .form-input.login
  .css-2y464i-MuiInputBase-root-MuiFilledInput-root:hover:not(
    .Mui-disabled,
    .Mui-error
  ):before {
  border-bottom: none !important;
}

.admin-main
  .form-group
  .form-input.login
  .css-2hzjrb-MuiInputBase-input-MuiFilledInput-input:-webkit-autofill {
  border: 1px solid #fff !important;
}

.admin-main .form-group .form-input.login input:-webkit-autofill,
.admin-main .form-group .form-input.login input:-webkit-autofill:hover,
.admin-main .form-group .form-input.login input:-webkit-autofill:focus,
.admin-main .form-group .form-input.login input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
  -webkit-text-fill-color: #ffffff;
}

.admin-main .form-group .form-input.login svg.css-i4bv87-MuiSvgIcon-root {
  fill: white;
}

.admin-main
  .form-group
  .form-input
  .css-1tzgnuz-MuiInputBase-root-MuiFilledInput-root,
.admin-main
  .form-group
  .form-input
  .css-2hzjrb-MuiInputBase-input-MuiFilledInput-input {
  padding-right: 0;
  /* background-color: #fff; */
  border-radius: 0;
  border: 1px solid #ffff;
}

.admin-main
  .form-group
  .form-input
  .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  margin-right: 0;
}

.admin-main .form-group .form-input fieldset,
.admin-main .form-group .form-input .MuiOutlinedInput-root:hover fieldset {
  border: 1px solid var(--secondary);
}

.admin-main
  .form-group
  .form-input
  .MuiOutlinedInput-root.Mui-focused
  fieldset {
  border: 1px solid var(--primary);
}

.admin-main .form-group .form-checkbox {
  margin: 0;
  gap: 5px;
  align-items: flex-start;
}

.admin-main .form-group .form-checkbox .MuiCheckbox-root {
  padding: 0;
}

.admin-main .form-group .form-checkbox .MuiSvgIcon-root {
  color: var(--primary);
  font-size: 20px;
}

.admin-main .form-group .form-checkbox .MuiTypography-root {
  font-size: 14px;
  line-height: 18px;
  color: #989898;
}

.admin-main .dahboard-common-header {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.admin-main .dahboard-common-header .header-title {
  font-size: 24px;
  line-height: 30px;
  font-family: "poppins-semibold";
}

.admin-main .common-table-main {
  box-shadow: var(--shadow-sm);
  border-radius: var(--radius6);
}

.admin-main .common-table-main .action-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-main .common-table-main .action-box button {
  padding: 8px;
  border-radius: 50%;
}

.admin-main .common-table-main .action-box svg {
  width: 20px;
  height: 20px;
}


.form-input p{
  margin-left: 4px;
}