.presale-wraper-prof {
  width: 100%;
  overflow-x: auto;
}

.presale-wraper-prof {
  width: 100%;
  padding: 15px;
  /* background: white; */
  border-bottom: 1px solid lightgray;
}

.presale-wraper-prof p {
  color: #240736;
  margin-bottom: 20px;
  font-size: 20px;
  font-family: "Poppins-medium";
}

.history-custom-btn-group-prof {
  display: flex;
  /* justify-content: space-between; */
  /* Adjust this value if you need different spacing */
}

.history-btn-prof {
  cursor: pointer;
  /* background: linear-gradient(to right, #00ee92, #d91ffb); */
  background: linear-gradient(99deg, #7b97c3 0%, #5a81ff 100%);
  padding: 2px 20px;
  border-radius: 10px;
  font-size: 14px;
  color: white;
  margin-right: 10px;
  font-weight: 500;
  line-height: 30px;
  font-family: 'Poppins-Regular';
}

.history-btn-prof span {
  margin: 0 10px;
}

.admin-edit-profile-main .admin-edit-profile-box {
  min-height: 380px;
  border: 1px solid lightgray;
  border-radius: 12px;
  margin-top: 20px;
}

.admin-edit-profile-box .edit-profile-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
}

.admin-edit-profile-box .change-password-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px 30px 0px;
}

.edit-profile-heading .heading-text,
.change-password-heading .heading-text {
  color: #240736;
  font-size: 16px;
  font-family: "Poppins-medium";
}

.heading-profile-image {
  position: relative;
  line-height: 0px;

}

.heading-profile-image .heading-profile {
  min-height: 80px;
  min-width: 80px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  margin: 5px 0px;
  box-shadow: 5px 5px 12px 0px lightgray;
}

.admin-edit-profile-box .edit-profile-content {
  margin: 0px 40px;
}

.form-group-prof p {
  font-size: 13px;
  font-family: "Poppins-medium";
  color: #240736;
}

.form-group-prof .form-input-prof .MuiInputBase-formControl input,
.form-group-prof
  .form-input-prof
  .MuiInputBase-formControl
  input:focus-visible {
  border: 1px solid lightgray;
  border-radius: 4px;
}

.edit-btn-box .edit-btn {
  border-radius: 8px;
  height: 33px;
  width: 100px;
  background-color: #445c96;
  color: #ffff;
  text-transform: capitalize;
  margin-bottom: 5px;
  font-size: 13px;
  font-family: "Poppins-Regular";
  gap: 5px;
}

.edit-btn-box .edit-btn svg{
  font-size: 16px;
}

.edit-btn-box .edit-btn:hover {
  background-color: #445c96;
}
.error-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-box .error-message {
  font-size: 13px;
  font-family: "Poppins-medium";
  color: #d32f2f;
}

.edit-btn-box {
  display: flex;
  justify-content: end;
  margin: 35px 0px;
}

.file-upload-btn-main .file-upload-btn,
.file-upload-btn-main .file-upload-btn:hover {
  background-color: #ffffff;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  min-width: 17px;
  padding: 0px;
  position: absolute;
  right: 0px;
  bottom: 13px;
  box-shadow: 0px 3px 6px #00000029;
}

.file-upload-btn-main {
  position: relative;
  max-width: max-content;
}

.upload-profile-img {
  height: 80px;
  width: 80px;
  min-height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.table-image {
  height: 35px;
  width: 40px;
  object-fit: cover;
}

.upload-icon-img {
  height: 8px;
  width: 8px;
  object-fit: contain;
}

.upload-image .file-upload-btn {
  height: 20px;
  position: absolute;
  min-width: 20px;
  padding: 10px 5px;
  bottom: 17px;
  right: 6px;
  background-color: #3c2649fb;
  border-radius: 50%;
}

.upload-image .file-upload-btn:hover {
  background-color: #3c2649fb;
}

.form-group-prof .profile-input-field input {
  border: 1px solid lightgray;
  border-radius: 4px;
  color: gray;
  font-size: 13px;
  font-family: "Poppins-regular";
}

.form-group-prof .change-password-input-field input {
  border: 1px solid lightgray;
  border-radius: 4px;
  margin-right: -50px;
  color: gray;
  font-size: 14px;
  font-family: "Poppins-regular";
}
