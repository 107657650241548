.dashboard-main-div .dashboard-top-box {
  /* border: 1px solid lightgray; */
  height: 260px;
  border-radius: 16px;
}

.dashboard-top-box .dashboard-heading {
  padding-bottom: 20px;
}
.dash-top-table .dash-top-table-head tr th {
  font-size: 13px;
  font-family: "Poppins-Regular";
  color: #ffff;
  /* background-color: #3D117C; */
  padding: 10px;
}
.dash-top-table .dash-top-table-head tr {
  background-image: linear-gradient(to right, #00ee92, #d91ffb);
}

.dash-top-table tr th:nth-child(1) {
  border-top-left-radius: 16px;
}

.dash-top-table tr th:nth-child(9) {
  border-top-right-radius: 16px;
}

.dash-top-table tr th:nth-child(1),
.dash-top-table tr td:nth-child(1) {
  width: 10%;
  font-family: "Poppins-Regular";
  font-size: 12px;
}
.dash-top-table tr th:nth-child(2),
.dash-top-table tr td:nth-child(2) {
  width: 10%;
}


.dash-body-cell-box {
  border: 1px solid lightgray;
  height: 38px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dash-body-cell-box .dash-top-input-textfield{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    /* padding-top: 10px; */
    font-family: "Poppins-Regular";
}


.dash-body-cell-button button{
  font-size: 11px;
  height: 33px;
  font-family: "Poppins-Regular";
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 13px;
  
  background-color: #445c96;
  color: #ffff;
  border-radius: 8px;
  width: max-content;
}
.dash-body-cell-button button:hover{
  background-color: #445c96;
  color: #ffff;
}

.dash-top-table tr th:nth-child(3),
.dash-top-table tr td:nth-child(3) {
  width: 10%;
}
.dash-top-table tr th:nth-child(4),
.dash-top-table tr td:nth-child(4) {
  width: 10%;
}
.dash-top-table tr th:nth-child(5),
.dash-top-table tr td:nth-child(5) {
  width: 10%;
}
.dash-top-table tr th:nth-child(6),
.dash-top-table tr td:nth-child(6) {
  width: 10%;
}

.dash-top-table tr th:nth-child(7),
.dash-top-table tr td:nth-child(7) {
  width: 10%;
}

.dash-top-table tr th:nth-child(8),
.dash-top-table tr td:nth-child(8) {
  width: 10%;
}

.dash-top-table tr th:nth-child(9),
.dash-top-table tr td:nth-child(9) {
  width: 10%;
}

.dashboard-top-box .dash-middle-box-main {
  /* display: flex; */
  margin: 20px;
  padding-bottom: 20px;
}

.change-recepient-box-main {
  border-radius: 12px;
  background-color: #fff;
  /* border: 1px solid lightgray; */
  width: 100%;
  padding: 12px;
  /* margin-right: 20px; */
}

.dash-btn-box .dash-btn {
background-color: #445c96;
  color: #ffff;
  border-radius: 16px;
  width: 112px;
  /* margin:20px 0px 10px 0px; */
  margin-top: 20px;
}

.dash-btn-box .dash-btn:hover {
background-color: #445c96;
}

.dash-btn-box .dash-btn-sale {
  background-color: #445c96;
    color: #ffff;
    border-radius: 16px;
    width: 112px;
    /* margin:20px 0px 10px 0px; */
  }
  
  .dash-btn-box .dash-btn-sale:hover {
  background-color: #445c96;
  }

.change-recepient-box-main .change-recepient-label {
  font-size: 15px;
  font-family: "Poppins-Regular";
  color: black;
  margin-bottom: 8px;
}
.change-recepient-box-main .input-field .Mui-focused .MuiOutlinedInput-notchedOutline ,
.change-recepient-box-main .input-field .MuiInputBase-formControl input,
.change-recepient-box-main .input-field .MuiInputBase-formControl .MuiSelect-select,
.change-recepient-box-main .input-field .MuiInputBase-formControl input:focus
.change-recepient-box-main .input-field .MuiInputBase-formControl input:hover{
    border: 1px solid lightgray;
    border-radius: 4px;   
    color: gray;
    font-size: 13px;
    font-family: "Poppins-regular";
}

.input-field label{
    font-size: 14px;
    font-family: 'Poppins-medium';
}


.change-recepient-box-main .input-field-date {
  margin-top: 12px;
}


.bg-change-recepient-box {
    padding: 1px;
    border-radius: 14px;
    background-image: linear-gradient(to right, #00ee92, #d91ffb);
}
.table-no-box {
    background-image: linear-gradient(to right, #00ee92, #d91ffb);
    padding: 1px;
    border-radius: 6px;
    height: 30px;
    width: 30px;
}
.table-no-box .table-no-icon-box{
    background-color: #ffff;
    border-radius: 7px;
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-no-icon-box .lock-icon{
    color: #11de9a;
}
.active-row{
    color: #11de9a;

}


.dashboard-heading .dash-table-container {
    border-radius: 16px;
    border: 1px solid lightgray;
    /* border-bottom: transparent; */
    overflow: auto;
}


.dashboard-top-box .presale-heading-box{
  padding: 10px;
}

.presale-heading-box p{
  font-family: 'Poppins-Medium';
    font-size: 18px;
    color: #240736;
}


.dash-body-cell-button .css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled {
 color: white;
 cursor: not-allowed;
 pointer-events: auto;
}
