.account-main-div {
  height: 100vh;
  margin-top: 110px;
  /* margin-bottom: 15px; */
  position: relative;
  overflow: hidden;
}

.login-section .account-page {
  margin: 20px 20px 20px 20px;
  min-height: 615px;
}

.my-account-heading-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 8px;
}

.my-account-claim-btn button {
  box-shadow: 1px 1px 0 0 #01faa3;
  border: 1px solid var(--main-white);
  border-radius: 25px;
  background-color: transparent;
  background-color: #1a1f2e;
  font-family: "Poppins-Regular";
  color: var(--main-white);
  text-transform: capitalize;
  font-size: 13px;
  padding: 7.3px 18px 7.7px;
  line-height: 22px;
  margin-right: 8px;
}

.my-account-heading-box .my-account-claim-btn,
.my-account-heading-box .my-account-heading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-account-heading-box .my-account-heading {
  font-family: "Poppins-Bold";
  font-size: 24px;
  color: #ffff;
  line-height: 40px;
  /* margin-bottom: 20px; */
  margin-left: 10px;
}

.stat-box {
  display: flex;
  width: 100%;
  gap: 30px;
}
.stat-box .stat-card {
  width: calc(100% / 3);
  display: flex;
  border: 1px solid #efefef;
  border-radius: 16px;
  padding: 20px;
  gap: 20px;
  position: relative;
  background: rgba(255, 255, 255, 1);
  /* background: rgba(255, 255, 255, 0.25); */
  box-shadow: 0 8px 32px 0 rgb(33 33 33 / 19%);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 1);
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
}
.stat-box .p-currency {
  background: var(--blue);
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stat.unlock .p-currency {
  background: var(--green);
}
.stat.available .p-currency {
  background: var(--black);
}
.stat-box .p-currency svg {
  height: 24px;
}
.stat-box h6 {
  font-size: 22px;
  font-weight: 600;
  font-family: "Poppins-regular";
  position: relative;
  color: #000000;
}

.stat-box .claim-lable {
  background: var(--blue);
  padding: 4px 15px 5px 15px;
  border-radius: 14px;
  display: inline-block;
  width: auto;
  position: absolute;
  right: 0;
  line-height: 1.25;
}
.stat-box .claim-lable span {
  color: var(--white);
  font-weight: 500;
  font-size: 13px;
}
.stat-box p {
  color: var(--black);
  opacity: 0.87;
  font-family: "Poppins-Regular";
}
.stat-box .text-wrape {
  width: calc(100% - 60px);
}

.stat-card-icon-box .stat-card-icon {
  height: 44px;
  width: 50px;
}

.account-pre-table-container {
  border-radius: 16px;
  border: 1px solid lightgray;
  /* border-bottom: transparent; */
}
.account-pre-table-container .account-pre-top-table {
  overflow: scroll;
}
.account-pre-top-table thead {
  background-image: linear-gradient(90deg, #00ee92, #d91ffb);
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
}
.account-pre-top-table thead tr th {
  color: #ffff;
  font-family: "Poppins-Regular";
  font-size: 12px;
  padding: 10px 7px;
  /* background-color: #240736; */
  text-align: center;

}

.account-pre-top-table .account-pre-top-table tr th {
  font-size: 13px;
  font-family: "Poppins-Regular";
  color: #ffff;
  /* background-color: #3D117C; */
  /* padding: 10px; */
}
.account-pre-top-table .account-pre-top-table tr {
  background-image: linear-gradient(to right, #00ee92, #d91ffb);
}

.account-pre-top-table tbody tr td {
  font-family: "Poppins-Regular";
  font-size: 12px;
  color: #ffff;
  padding: 7px 6px;
  background-color: #461b60;
  display: flex;
  justify-content: center;
  align-items: center;

}

.account-pre-top-table tr th:nth-child(1) {
  border-top-left-radius: 16px;
}

.account-pre-top-table tr th:nth-child(10) {
  border-top-right-radius: 16px;
}

.account-pre-top-table tr th:nth-child(1),
.account-pre-top-table tr td:nth-child(1) {
  width: 8%;
}
.account-pre-top-table tr th:nth-child(2),
.account-pre-top-table tr td:nth-child(2) {
  width: 8%;
}

.dash-body-cell-box {
  border: 1px solid lightgray;
  height: 38px;
  border-radius: 6px;
}

.account-pre-top-table tr th:nth-child(3),
.account-pre-top-table tr td:nth-child(3) {
  width: 10%;
}
.account-pre-top-table tr th:nth-child(4),
.account-pre-top-table tr td:nth-child(4) {
  width: 10%;
}
.account-pre-top-table tr th:nth-child(5),
.account-pre-top-table tr td:nth-child(5) {
  width: 10%;
}
.account-pre-top-table tr th:nth-child(6),
.account-pre-top-table tr td:nth-child(6) {
  width: 10%;
}

.account-pre-top-table tr th:nth-child(7),
.account-pre-top-table tr td:nth-child(7) {
  width: 10%;
}
.account-pre-top-table tr td:nth-child(7) button {
  cursor: pointer;
  color: white;
  text-transform: capitalize;
  box-shadow: 0.5px 1px 0 0 #01faa3;
  border: 1px solid var(--main-white);
  border-radius: 25px;
  background-color: transparent;
  background-color: #1a1f2e;
  font-family: "Poppins-Regular";
  color: var(--main-white);
  text-transform: capitalize;
  font-size: 11px;
  padding: 1.3px 19px 0.7px;
  line-height: 23px;
}
.account-pre-top-table tr th:nth-child(8),
.account-pre-top-table tr td:nth-child(8) {
  width: 10%;
}
.account-pre-top-table tr th:nth-child(9),
.account-pre-top-table tr td:nth-child(9) {
  width: 10%;
}

.account-pre-top-table tr th:nth-child(10),
.account-pre-top-table tr td:nth-child(10) {
  width: 8%;
}

.account-page .my-accout-custom {
  color: #fff;
}

.my-accout-custom .MuiTablePagination-input svg {
  fill: #ffff;
}
.my-accout-custom .MuiIconButton-colorInherit svg {
  fill: #ffff;
}

.account-pre-top-table .account-table-data {
  max-height: 350px;
  overflow: auto;
}



.table-scroll{
  /*width:100%; */
  display: block;
  empty-cells: show;
}

.table-scroll tbody{
  /* Position */
  display: block;
   position:relative;
  width:100%;
   /* overflow-y:scroll; */
  /* Decoration */
  border-top: 1px solid rgba(0,0,0,0.2);
}

.table-scroll tr{
  width: 100%;
  display:flex;
}

.table-scroll td,.table-scroll th{
  flex-basis:100%;
  flex-grow:2;
  display: block;
  padding: 1rem;
  text-align:left;
}

.table-body-screen{
  max-height: 65vh;
}

.account-pre-table-container::-webkit-scrollbar {
  width: 6px;
  border-radius: 16px;
}

/* Track */
.account-pre-table-container::-webkit-scrollbar-track {
  border-radius: 90vh;
}


.account-pre-table-container::-webkit-scrollbar-thumb {
  background: linear-gradient(rgb(134, 239, 172), rgb(59, 130, 246), rgb(147, 51, 234));
  border-radius: 10px;
}

.my-account-claim-btn .my-account-search-input input{
  font-size: 12px;
  color: white;
  font-family: "Poppins-Regular";
  margin-right: 14px;

}

.my-account-claim-btn .my-account-search-input{
  border: 1px solid whitesmoke;
  border-radius: 14px;
  width: 220px;
  height: 38px;
}

.my-account-claim-btn .my-account-search-input .Mui-focused .MuiOutlinedInput-notchedOutline{
  border: none;
}

.my-account-claim-btn .my-account-search-input:hover{
  border: 1px solid whitesmoke;
}

.my-account-claim-btn .select-round{
  border: 1px solid whitesmoke;
  border-radius: 14px;
  width: 200px;
  height: 38px;
}

.my-account-claim-btn .select-round {
  font-size: 12px;
  color: white;
  font-family: "Poppins-Regular";
}
.my-account-claim-btn .select-round fieldset{
  border: none;
}
.my-account-claim-btn .select-round  svg{
  fill: #fff;
}

.account-select-list-menu{
  border-radius: 0px !important;
}

.account-select-list-menu ul{
  color: white;
  background-color: #461b60;
}
.account-select-list-menu ul .Mui-selected{
  background-color: #a253e0 !important;
}

.my-account-heading-box .my-account-claim-btn {
  width: auto;
  gap: 12px;
}





::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 16px;
}

::-webkit-scrollbar-track {
  border-radius: 100vh;
}


 ::-webkit-scrollbar-thumb {
  background: linear-gradient(rgb(134, 239, 172), rgb(59, 130, 246), rgb(147, 51, 234));
  border-radius: 10px;
}

textarea::-webkit-scrollbar {
  width: 8px;
  border-radius: 16px;
}

textarea::-webkit-scrollbar-track {
  border-radius: 100vh;
}


textarea::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 10px;
}


.my-account-claim-btn .css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: white;
}