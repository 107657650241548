.token-field-btn .remove-btn{
    border-radius: 8px;
    height: 22px;
    width: 26px;
    background-color: red;
    color: #ffff;
    text-transform: capitalize;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.token-field-btn .remove-btn svg,
.token-field-btn .add-btn svg{
    width: 16px;
    height: 16px;
}


.token-field-btn .remove-btn:hover{
    background-color: red;
}
.token-field-btn .add-btn:hover{
    background-color: green;

}
.token-field-btn .add-btn{
    border-radius: 8px;
    height: 22px;
    width: 26px;
    margin-top: 15px;
    background-color: green;
    color: #ffff;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.token-field-btn {
    display: flex;
align-items: center;
justify-content: flex-end;
}

.social-form-box .tokenomics-row{
    margin-bottom: 20px;
}

.social-form-box .token-list-main-box{
    display: flex;
}

.token-list-main-box .token-index-list{
    margin-right: 5px;
    font-size: 14px;
    font-size: 13px;
    font-family: "Poppins-medium";
    color: #240736;
}