.roadmap-three-main {
      background-image: url(../../../../../assets/images/png/roadmap-3-bg.png);
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      width: 100%;
      padding: 100px 0px 100px 0;
      background-color: #240736;
      overflow: hidden;
}

.roadmpa-title-after {
      margin: auto;
      display: flex;
      justify-content: center;
}

.roadmap-three-main-title .roadmap-three-heading {
      margin-bottom: 65px;
      line-height: 60px;
      font-size: 52px;
      font-family: 'poppins-bold';
}

.roadmap-three-main-title {
      text-align: center;
}

.roadmap-three-list-main .roadmap-three-ul {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 50px 0;
}

.roadmap-three-ul .roadmap-three-li {
      width: 400px;
      padding: 0px;
      margin: 0px;
}

.roadmap-three-ul .roadmap-three-li-right {
      margin-left: auto;
      text-align: left;
      justify-content: end;
}

.roadmap-three-ul .roadmap-three-li-right .roadmap-three-year-box {
      left: calc(-36% - 18%);
}


.roadmap-three-year-box {
      height: 40px;
      width: 120px;
      background: linear-gradient(119deg, #d91ffb, #00ee92 2%, #d91ffb);
      border-radius: 6px;
      position: absolute;
      right: calc(-36% - 18%);
      top: calc(46% - 6%);
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
}

.roadmap-three-ul .roadmap-three-li.roadmap-three-li-left:after {
      content: "";
      right: -23%;
      top: 50%;
      width: 24%;
      position: absolute;
}

.roadmap-three-ul .roadmap-three-li.roadmap-three-li-right:after {
      content: "";
      left: -24%;
      top: 50%;
      width: 24%;
      position: absolute;
}


.roadmap-three-ul .roadmap-three-li:after {
      content: "";
      display: block;
      height: 2px;
      background-image: linear-gradient(to right, transparent 49%, #C2A7E8);
      background-size: 8px 5px;
      width: 4px;
      position: absolute;
      width: 24%;
      z-index: 0;
}



.roadmap-three-list-main .roadmap-three-ul:after {
      content: "";
      display: block;
      position: absolute;
      left: calc(50% - 2px);
      height: 100%;
      background-image: linear-gradient(to top, transparent 49%, #C2A7E8);
      background-size: 9px 9px;
      width: 2px;
      top: 0;
      bottom: 0;
      border-radius: 5px;
}

.roadmap-three-year-box .roadmap-three-year-text {
      color: var(--main-white);
      font-size: 16px;
      line-height: 25px;
      font-family: 'Poppins-Medium';
}

.roadmap-three-content-box {
      box-shadow: 10px 10px 21px #0000000d;
      padding: 30px;
      border-radius: 20px;
      position: relative;
      z-index: 1;
      background: #d91ffb0f;
      top: 0;
      max-width: 400px;
      width: 100%;
      opacity: 1;
}

.roadmap-three-main-sec {
      overflow: hidden;
}

.roadmap-three-content-box .roadmap-three-content-title {
      color: var(--main-white);
      font-size: 20px;
      line-height: 30px;
      font-family: 'Poppins-Medium';
      margin-bottom: 8px;
      display: -webkit-box;
      /* -webkit-line-clamp: 1; */
      -webkit-box-orient: vertical;
      overflow: hidden;
      min-height: 30px;
}

.roadmap-three-content-box .roadmap-three-content-para {
      color: var(--main-white);
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      font-family: 'Poppins-Light';
      display: -webkit-box;
      /* -webkit-line-clamp: 4; */
      -webkit-box-orient: vertical;
      overflow: hidden;
      min-height: 100px;
}

@media screen and (min-width: 1200px) and (max-width: 1299px) {}


@media screen and (min-width: 992px) and (max-width: 1199px) {
      .roadmap-three-ul .roadmap-three-li-left .roadmap-three-year-box {
            right: calc(-36% - -5%);
      }

      .roadmap-three-ul .roadmap-three-li-left .roadmap-three-content-box {
            max-width: 350px;
            margin-right: auto;
      }

      .roadmap-three-ul .roadmap-three-li-right .roadmap-three-content-box {
            max-width: 350px;
            margin-left: auto;
      }

      .roadmap-three-ul .roadmap-three-li.roadmap-three-li-left:after {
            right: -12%;
      }

      .roadmap-three-ul .roadmap-three-li-right .roadmap-three-year-box {
            left: calc(-36% - -6%);
            width: 107px;
      }

      .roadmap-three-ul .roadmap-three-li.roadmap-three-li-right:after {
            left: -12%;
      }

      .roadmap-three-list-main .roadmap-three-ul:after {
            height: calc(100% - 6%);
      }

  
}

@media screen and (max-width: 991px) {}

@media screen and (min-width: 768px) and (max-width: 991px) {
      .roadmap-three-ul .roadmap-three-li-right .roadmap-three-year-box {
            left: calc(-36% - -37%);
            width: 107px;
      }

      .roadmap-three-ul .roadmap-three-li-left .roadmap-three-year-box {
            right: calc(-36% - -37%);
            width: 107px;
      }

      .roadmap-three-ul .roadmap-three-li.roadmap-three-li-right:after {
            left: 20%;
      }

      .roadmap-three-ul .roadmap-three-li-left .roadmap-three-content-box {
            max-width: 250px;
            margin-right: auto;
      }

      .roadmap-three-ul .roadmap-three-li-right .roadmap-three-content-box {
            max-width: 250px;
            margin-left: auto;
      }

   

      .roadmap-three-list-main .roadmap-three-ul:after {
            height: calc(100% - 6%);
      }

      .roadmap-three-year-box .roadmap-three-year-text {
            font-size: 14px;
            line-height: 19px;
      }

      .roadmap-three-content-box {
            max-width: 260px;
            padding: 15px;
      }

      .roadmap-three-ul .roadmap-three-li.roadmap-three-li-left:after {
            right: 14%;
      }

      .roadmap-three-main-title .roadmap-three-heading {
            margin-bottom: 40px;
            font-size: 40px;
            line-height: 42px;
      }
}

@media (max-width: 767px) {
      .roadmap-three-main{
            padding: 50px 0px;
      }
      .roadmap-three-main-title .roadmap-three-heading {
            font-size: 30px;
            line-height: 32px;
            margin-bottom: 40px;
      }

      .roadmap-three-list-main .roadmap-three-ul {
            margin: 0px 0;
      }

      .roadmap-three-ul .roadmap-three-li-right .roadmap-three-year-box {
            top: calc(50% - 72%);
            left: 5%;
      }

      .roadmap-three-ul .roadmap-three-li-left .roadmap-three-year-box {
            top: calc(50% - 72%);
            left: 5%;
      }

      .roadmap-three-ul .roadmap-three-li.roadmap-three-li-left:after {
            left: 0%;
            top: -18%;
      }

      .roadmap-three-ul .roadmap-three-li.roadmap-three-li-right::after {
            left: 0%;
            top: -16%;
      }

      .roadmap-three-list-main .roadmap-three-ul:after {
            left: 0%;
            height: calc(100% - 50px);
      }

      .roadmap-three-ul .roadmap-three-li {
            width: 100%;
      }

      .roadmap-three-content-box {
            margin-bottom: 80px;
            margin-left: 15px;
            max-width: initial;
            width: initial;
      }

      .roadmap-three-ul .roadmap-three-li:last-child .roadmap-three-content-box {
            margin-bottom: 0px;
            margin-top: 15px;
      }
}



.roadmap-three-content-para span{
      font-size: 12px;
      font-weight: 400;
      line-height: 35px;
      font-family: 'Poppins-Regular';
      color: #ffff;
      cursor: pointer;
  }