@import url("../../../../assets/style/style.css");

.footer-one-gradient-border {
  background: transparent linear-gradient(90deg, #d91ffb, #00ee92 42%, #d91ffb)
    0 0 no-repeat padding-box;
  height: 5px;
}

.bg-footer-light-top {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;
  background-image: linear-gradient(
      45deg,
      rgba(36, 07, 54, 0.9),
      rgba(36, 07, 54, 0.9)
    ),
    url(../../../../assets/images/svg/footer-one-bg.svg);
  padding: 50px 0px;
  align-items: center;
  display: flex;
}

.pd-light-content-footer {
  padding: 0px 0px;
}

.bg-footer-light-top .brand-light-left-col {
  display: flex;
  align-items: center;
  gap: 11px;
}

.brand-light-left-col .footer-brand-logo {
  height: 43px;
  max-width: 94px;
  object-fit: contain;
}

.brand-light-left-col .logo-footer-content {
  margin-bottom: 20px;
}

.grid-footer-content .footer-add-light-logo .list-add-logo-light {
  color: var(--main-white);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  font-family: "Poppins-Regular";
  display: -webkit-box;
  /* -webkit-line-clamp: 3; */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.list-social-icon-footer {
  display: flex;
  justify-content: flex-end;
}
.other-link-footer-light .list-footer-title-light {
  font-size: 31px;
  line-height: 56px;
  margin-bottom: 20px;
  color: var(--main-white);
  font-family: "Poppins-Bold";
  width: 270px;
}

.other-link-footer-light .light-center-other-link .listitem-light-content {
  padding: 0px 0px 15px 0px;
}

.other-link-footer-light
  .light-center-other-link
  .listitem-light-content
  .refrence-link-light-footer {
  color: var(--main-white);
  text-decoration: none;
  font-family: "Poppins-Regular";
  font-size: 14px;
  line-height: 20px;
}

.other-link-footer-light .light-center-other-link .listitem-light-content {
  padding: 0px 0px 15px 0px;
}

.other-link-footer-light
  .light-center-other-link
  .listitem-light-content
  .refrence-link-light-footer {
  color: var(--main-white);
  text-decoration: none;
  font-family: "Poppins-Regular";
  font-size: 14px;
  line-height: 20px;
}

.light-footer-social-icon .footer-light-social-ul {
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.light-footer-social-icon{
    padding-left: 20px;
}

.light-footer-social-icon .footer-light-social-ul .footer-light-socil-li {
  padding: 0px;
}

.light-footer-social-icon .footer-light-social-ul .footer-light-social-box {
  background-color: var(--main-gray-dark);
  border: 0px;
  box-shadow: none;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  transition: all 0.5s;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}
.footer-light-social-ul
  .footer-light-social-box
  .footer-one-social-icons:hover {
  border-radius: 50%;
}

.footer-light-social-ul .footer-light-social-box .footer-one-social-icons {
  height: 100%;
  width: 100%;
  transition: all 0.5s;

  object-fit: contain;
}

/* .light-footer-social-icon .footer-light-social-ul .footer-light-social-box:hover{
   border: 2px solid #d91ffb;
} */

.light-footer-social-icon .footer-light-social-ul .show-black-light,
.light-footer-social-icon
  .footer-light-social-ul
  .footer-light-social-box:hover
  .hide-hover-light {
  display: block;
}

.light-footer-social-icon
  .footer-light-social-ul
  .footer-light-social-box:hover
  .show-black-light {
  display: block;
}

.other-link-footer-light
  .light-center-other-link
  .listitem-light-content:last-child {
  padding-bottom: 0px;
}

.bg-footer-light-bottom {
  background-color: #240736;
  padding: 20px 0px;
}

.copyright-light-content p {
  text-align: center;
  font-size: 14px;
  color: var(--main-white);
  font-family: "Poppins-Regular";
  line-height: 20px;
}

.footer-light-social-box .footer-light-social-link {
  display: flex;
}

.footer-light-social-main .footer-light-social-ul {
  padding: 0px;
  margin: 0px;
  display: flex;
  list-style-type: none;
  gap: 20px;
}

.footer-light-social-main .footer-light-socil-li {
  padding: 0px;
  margin: 0px;
  width: max-content;
}

.footer-light-social-icons {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.footer-logo {
  height: 150px;
  width: 150px;
  object-fit: contain;
}

/* footer res start */

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .grid-footer-content .footer-add-light-logo .list-add-logo-light {
    font-size: 13px;
  }

  .brand-light-left-col .logo-footer-content {
    margin-bottom: 15px;
  }

  .light-footer-social-icon .footer-light-social-ul .footer-light-social-box {
    margin-right: 10px;
  }
}

/* phone and tablets */
@media screen and (max-width: 991px) {
}

/* tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .footer-logo {
    height: 95px;
    width: 95px;
  }
  .other-link-footer-light .list-footer-title-light {
    font-size: 25px;
    line-height: 40px;
    margin-bottom: 9px;
  }
}

/* phone */
@media (max-width: 767px) {
  .list-social-icon-footer {
    justify-content: center;
  }
  .bg-footer-light-top .brand-light-left-col {
    flex-direction: column;
    justify-content: center;
  }
  .grid-footer-content .footer-add-light-logo .list-add-logo-light {
    text-align: center;
  }
  .other-link-footer-light .list-footer-title-light {
    text-align: center;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .light-footer-social-icon .footer-light-social-ul .footer-light-social-box {
    width: 45px;
    height: 45px;
    margin-right: 9px;
  }

  .footer-one-social-icons {
    width: 15px;
    height: 15px;
  }

  .brand-light-left-col .logo-footer-content {
    margin-bottom: 15px;
  }
}

/* footer res end */
