.admin-edit-profile-main .section-box {
  min-height: 340px;
    border: 1px solid lightgray;
    border-radius: 12px;
    margin-top: 20px;
  }
  
  .form-group-prof .white-paper-input-box{
    display: flex;
    max-width: 470px;
    border: 1px solid lightgray;
    border-radius: 6px;
    background: #f0f0f0;
  }

  .form-group-prof .white-paper-input-box input {
    border: none;
    border-radius: 4px;
    color: gray;
    font-size: 13px;
    font-family: "Poppins-regular";
    background: #f0f0f0;
}

.white-paper-input-box .profile-input-field{
  width: 104px;
}

.white-paper-input-box p{
  color: gray;
  margin: 9px;

}

.form-group-prof .error-message{
  color: #d32f2f;
  margin-left: 4px;
  font-size: 13px;
}

.white-paper-input-box .pdf-name-view{
  color: gray;
  font-size: 13px;
  font-family: "Poppins-regular";
}