.here-one-imges {
  width: 462px;
  height: 511px;
  object-fit: contain;
  animation: hero1imgbounce 5s linear infinite;
  margin: auto;
  display: flex;
}

.one-herosection-row {
  /* margin-top: 124px; */
  padding: 124px 0px;
  align-items: center;
  position: relative;
  z-index: 99;
}

.hero-one-sec {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 124px;
  padding-bottom: 50px;
  position: relative;
  overflow: hidden;
}

.hero-one-sec::after {
  content: "";
  position: absolute;
  left: 18%;
  top: 17%;
  background-image: url(../../../../../assets/images/video/dog.gif);
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  opacity: 0.1;
}

/* .hero-one-sec::before {
      content: '';
      position: absolute;
      right: -229px;
      top: 98px;
      background-image: url(../../../../../assets/images/png/dog.png);
      height: 100vh;
      width: 100%;
      background-repeat: no-repeat;
      opacity: 0.1;
} */

.hero-btn-flex {
  display: flex;
  gap: 10px;
}

.border-btn-main .hero-one-buy-btn {
  max-height: 50px;
}

.hero-one-slider-details .hero-one-slider-heading {
  font-size: 52px;
  line-height: 78px;
  margin-right: 20px;
  min-width: 380px;
  font-family: "Poppins-Bold";
  color: var(--main-white);
  margin-bottom: 30px;
  /* min-height: 156px; */
}

.hero-one-slider-details .hero-one-slider-para {
  font-family: "Poppins-Light";
  font-size: 18px;
  line-height: 32px;
  color: var(--main-primary);
  margin-bottom: 30px;
  color: var(--main-primary);
  margin-bottom: 60px;
  display: -webkit-box;
  /* -webkit-line-clamp: 4; */
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 108px;
  max-width: 503px;
}

.hero-one-slider-images {
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  overflow: hidden;
  /* background-image: linear-gradient(45deg, rgba(36, 07, 54, 0.9), rgba(36, 07, 54, 0.9)), url(../../../../../assets/images/svg/footer-one-bg.svg); */

  background-image: url(../../../../../assets/images/svg/hero-bg.svg);
  background-repeat: no-repeat;
  background-color: #240736;
}

.hero-one-slider-main .hero-one-slider-images-main .hero-one-slider-images {
  width: 100%;
  min-height: 100vh;
}

.hero-one-slider-sec .hero-one-details {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: -124px;
}

.header-one-header-sec
  .header-list-light-details
  .header-list-light
  .header-listitem-light:nth-child(6) {
  padding-right: 0px;
}
@keyframes logozoom {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}
.logo-img {
  height: 100px;
  width: 100px;
  object-fit: contain;
  margin: 0px auto 20px;
  display: flex;
  animation: logozoom 1s infinite ease-in-out alternate;
  -webkit-animation: logozoom 1s infinite ease-in-out alternate;
}
.timer-count-mian {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.timer-box {
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: #ffffff1a;
  color: var(--main-white);
  max-width: 70px;
  min-width: 70px;
  width: 100%;
}

.timer-box .timer-count {
  color: var(--main-white);
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins-Medium";
  text-align: center;
  display: -webkit-box;
  /* -webkit-line-clamp: 1; */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.timer-flex .timer-text {
  color: #01faa3;
  font-size: 15px;
  line-height: 21px;
  font-family: "Poppins-Bold";
  text-align: center;
  display: -webkit-box;
  /* -webkit-line-clamp: 1; */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
}

.timer-main {
  margin: 0px 0px 20px 0px;
}

.timer-flex {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  max-width: 350px;
  margin: auto;
}

.presale-box {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background: #24073600;
  border: 3px solid #01faa3;
  padding: 30px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  /* background-color: rgba(255, 255, 255, 0.13); */
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
}

.presale-haeder {
  background-color: #ffffff1a;
  padding: 10px 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.presale-box-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.presale-content-main .presale-price-text {
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins-Medium";
  color: var(--main-white);
}

.presale-box-flex .presale-pay-text {
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins-Medium";
  color: var(--main-white);
  text-align: center;
}

.presale-content-main .presale-price {
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins-Medium";
  color: var(--main-white);
  text-align: center;
}

.presale-content-main {
  padding: 30px 30px 0px 30px;
}

.presale-btn-main {
  margin: 20px 0px 0px;
  display: flex;
  justify-content: center;
}

.presale-content-main .presale-sold-text {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Medium";
  color: var(--main-white);
  text-align: center;
  text-transform: uppercase;
  margin: 10px 0px;
}
.presale-input-value-box {
  padding: 12px;
  border-radius: 10px;
  background-color: #ffffff1a;
  color: #ffff;
  font-family: "Poppins-Medium";
}
.presale-input-flex {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 25px;
}
@media screen and (min-width: 1200px) and (max-width: 1299px) {
}

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-one-slider-sec {
    margin-top: -95px;
  }

  .hero-one-slider-details {
    padding-top: 15px;
  }

  .hero-one-sec {
    padding-top: 95px;
  }

  .hero-one-slider-details .hero-one-slider-heading {
    font-size: 37px;
  }

  .hero-one-sec,
  .hero-one-slider-main .hero-one-slider-images-main .hero-one-slider-images {
    min-height: 700px;
  }

  .hero-one-slider-details .hero-one-slider-heading {
    font-size: 40px;
    line-height: 55px;
  }

  .hero-one-slider-main .owl-carousel .owl-item img {
    width: 80%;
    height: 440px;
    object-fit: contain;
    margin: auto;
  }

  .hero-one-slider-main .hero-one-slider .owl-dots {
    right: 1%;
    top: 46%;
  }
  .one-herosection-row {
    margin-top: 124px;
  }
}

/* phone and tablets */
@media screen and (max-width: 991px) {
}

/* tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .hero-one-slider-details .hero-one-slider-heading {
    font-size: 29px;
    line-height: 32px;
    min-width: 100%;
    margin-bottom: 27px;
  }

  .hero-one-slider-details .hero-one-slider-para {
    font-size: 15px;
    line-height: 27px;
    max-width: 100%;
    margin-bottom: 40px;
  }

  .one-herosection-row {
    align-items: center;
  }

  .here-one-imges {
    height: 439px;
    max-width: 287px;
    margin: auto;
  }

  .hero-one-slider-main .hero-one-slider-images-main .hero-one-slider-images {
    min-height: 600px;
  }

  .hero-one-slider-main .hero-one-slider .owl-dots {
    right: 2%;
    top: 47%;
  }

  .hero-one-sec {
    min-height: 600px;
  }
  .one-herosection-row {
    padding: 0;
  }
}

/* phone */
@media (max-width: 767px) {
  .presale-box-flex {
    flex-direction: column;
  }
  .timer-box {
    max-width: 50px;
    min-width: 50px;
  }
  .hero-one-slider-main .hero-one-slider-images-main .hero-one-slider-images {
    min-height: 100vh;
  }
  .hero-one-sec::after {
    left: 0;
  }
  .hero-one-slider-sec {
    margin-top: 0;
  }
  .hero-one-slider-details {
    margin-top: 50px;
  }
  .one-herosection-row {
    padding: 124px 0px 0px;
  }
  .hero-one-slider-sec .hero-one-sec {
    padding-top: 0px;
  }

  .hero-one-slider-sec .hero-one-slider {
    padding: 78px 0px;
    min-height: 100vh;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .hero-one-slider-details .hero-one-slider-heading {
    font-size: 25px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .hero-one-slider-details .hero-one-slider-para {
    font-size: 15px;
    line-height: 25px;
    max-width: 100%;
    margin-bottom: 36px;
  }

  .here-one-imges {
    min-width: 300px;
    height: 300px;
  }

  .hero-one-slider-main .hero-one-slider .owl-dots {
    flex-direction: initial;
    right: initial;
    top: initial;
    bottom: 0%;
    left: 5%;
  }

  .hero-one-slider-main .hero-one-slider .owl-dots .owl-dot span {
    margin: 0px 13.5px 0px;
  }

  .grid-main .order2 {
    order: 2;
  }

  .grid-main .order1 {
    order: 1;
  }
}

.claim-main-modal .claim-box-modal-main {
  width: 410px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #240736;
  border-radius: 15px;
  outline: none;
  padding: 30px 55px;
  max-height: 880px;
  overflow: auto;
}

.claim-box-modal-main .claim-modal-heading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.claim-box-modal-main .claim-dropdown-box{
      margin-bottom: 30px;
}

.claim-modal-heading p {
  font-size: 23px;
  font-family: "Poppins-Bold";
  margin-bottom: 30px;
  color: #fff;
  word-spacing: 5px;
}

.claim-dropdown-box .claim-dropdown {
  color: #fff;
  margin-bottom: 10px;
}

.claim-dropdown .MuiSelect-select {
border: 1px solid #fff !important;
}

.claim-dropdown-box .claim-dropdown.Mui-focused fieldset{
border: 1px solid #fff !important;
}

.claim-dropdown svg{
      fill: #fff;
}


.form-group .form-control .MuiInputBase-formControl svg{
  fill: #fff;
}
.form-group .form-control .MuiInputBase-formControl .MuiSelect-select {
  border: 0;
  width: 100%;
  padding: 14.29px 40px 12.71px;
  background-color: #FFFFFF1A;
  border-radius: 10px;
  font-size: 16px;
  line-height: 25px;
  color: var(--main-white-light);
  font-family: 'Poppins-Regular';
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.menu-item{
  font-size: 16px !important;
  line-height: 25px !important;
  font-family: "Poppins-Regular" !important;
}
.form-group .form-control .MuiInputBase-formControl .MuiSelect-select::placeholder {
font-size: 16px;
line-height: 25px;
font-family: "Poppins-Regular";
opacity: 1;
}

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
border-color: transparent;
border-width: 0px;
}


.claim-box-modal-main .claim-btn-box{
  margin: 30px 0px 10px;
}

.claim-input-field .input-error{
  margin: 5px;
  font-family: 'Poppins-Regular';
}

.amount-input-purchase .amount-error-box{
  height: 5px;
}
.presale-input-box .amount-input-purchase{
  height: 50px;
}

.amount-error-box p{
  color: red;
  font-size: 14px;
  font-family: "Poppins-Medium";
}

.amount-input-purchase .form-control input {
  padding: 14.29px 30px 12.71px !important;
}