@media screen and (min-width: 1920px) {
      .roadmap-three-year-box {
            right: calc(-73% - 18%);
      }

      .roadmap-three-ul .roadmap-three-li.roadmap-three-li-left:after {
            right: -62%;
            width: 62%;
      }

      .roadmap-three-ul .roadmap-three-li-right .roadmap-three-year-box {
            left: calc(-73% - 18%);
      }

      .roadmap-three-ul .roadmap-three-li.roadmap-three-li-right:after {
            left: -61%;
            width: 60%;
      }
}

@media screen and (min-width: 1200px) and (max-width: 1299px) {
      .container {
            max-width: 1440px;
      }
}

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {
      .container {
            max-width: 960px;
      }


      .gradient-btn-main .gredient-btn,
      .gradient-btn-main .gradient-btn:hover {
            padding: 14.39px 28px 12.71px;
      }
}

/* phone and tablets */
@media screen and (max-width: 991px) {}

/* tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {
      .body-overflow {
            overflow: hidden;
      }

      .container {
            max-width: 720px;
      }
      
      .select-box .select-round {
            margin-bottom: 3px;
           }
    
           .pre-history-heading-box .select-box {
               display: flex;
               flex-direction: column;
               gap: 2px;
           }
           .presale-input-flex {
            flex-direction: column;
            gap: 20px;
        }
        .account-main-div {
            margin-top: 75px;
            margin-bottom: 0px;
            height: 98vh;
      }
   
}

/* phone */
@media (max-width: 767px) {

    

    .close-icon-phone{
        display: block;
    }

    .admin-main .admin-main-right {
        padding-left: 0px;
    }

      .form-group .form-control input {
            padding: 14px;
       }
      .order-res-1 {
            order: 1;
      }

      .order-res-2 {
            order: 2;
      }

      .body-overflow {
            overflow: hidden;
      }

      .info-light-title-main .info-titel-light {
            margin-bottom: 20px;
      }
      
      .select-box .select-round {
            margin-bottom: 3px;
           }
           .pre-history-heading-box .select-box {
            display: flex;
            flex-direction: column;
            gap: 2px;
        }

        
        .amount-error-box p {
            margin-left: 10px;
            color: red;
            font-size: 12px;
            font-family: "Poppins-Medium";
        }

        .presale-input-flex {
            flex-direction: column;
            gap: 20px;
        }

        
.my-account-heading-box .my-account-claim-btn {
      gap: 6px;
          flex-direction: column;
      }
      
      .my-account-claim-btn .select-round {
        min-width: 150px;
          max-width: 200px;
      }
      
      .my-account-claim-btn .my-account-search-input {
          max-width: 200px;
      }

      .account-main-div {
            margin-top: 88px;
            margin-bottom: 0px;
            height: 97vh;
      }
      .hero-one-slider-details {
            margin-bottom: 25px;
        }

        .account-page .my-accout-custom {
            overflow:hidden;
            }

            .my-account-heading-box .my-account-heading {
                  font-size: 20px;
                  line-height: 27px;
                  margin-right: 5px;
               }
        
              .wallet-adapter-dropdown button {
                  padding: 14.3px 15px 12.7px;
                  height: 36px;
                  font-size: 14px;
              }

              .header-row-common .header-right {
                  gap:8px;
              }
              .header-one-header-sec .header-logo-com .header-refreal-link .logo-brand-header {
                  min-width: 65px;
              }

              .feature-one-main .feature-one-card-text {
                  overflow: auto;
                  height: 300px;
                  margin-bottom: 20px;
              }

              .account-pre-top-table thead tr th {
                  padding: 10px 24px;
                  display: flex;
                 justify-content: center;
                align-items: center;
              }

              .my-accout-custom .MuiTablePagination-toolbar .css-rmmij8 ,
              .table-list-pagination .MuiTablePagination-toolbar .css-rmmij8 {
                    margin-right: 2px !important;
                    margin-left: 0px !important;
                }
  
                .my-accout-custom .MuiTablePagination-toolbar .MuiTablePagination-actions ,
                  .table-list-pagination .MuiTablePagination-toolbar .MuiTablePagination-actions {
                    margin-left: 4px !important;
                }
           
              .my-account-claim-btn button {
                  padding: 7.3px 38px 7.7px;
              }
              .user-list-top-table .private-pre-table-head tr th {
                  padding: 10px 50px;
              }
              .private-pre-top-table .private-pre-table-head tr th:nth-child(3) {
                  padding: 10px 80px;
              }

              .private-pre-top-table .private-pre-table-head tr th:nth-child(4) {
                padding: 10px 40px;
            }

              .feature-list-top-table .private-pre-table-head tr th:nth-child(3) {
             padding: 10px 50px;
            }

            .feature-list-top-table .private-pre-table-head tr th:nth-child(4) {
                  padding: 10px 150px;
                 }

          .feature-list-top-table .private-pre-table-head tr th:nth-child(5) {
                  padding: 10px 20px;
                 }

   .roadmap-list-top-table .private-pre-table-head tr th:nth-child(2) {
            padding: 10px 50px;

        }

        .roadmap-list-top-table .private-pre-table-head tr th:nth-child(3) {
            padding: 10px 120px;

        }
        .roadmap-list-top-table .private-pre-table-head tr th:nth-child(5) {
            padding: 10px 20px;

        }

        .presale-userlist .feature-heading-box {
            gap: 50px;
        }

        .feature-heading-box .roadmap-add-btn,
        .feature-heading-box .feature-add-btn {
            margin-top: 5px;
        }

        .admin-progress-bar-text{
            font-family: 'Poppins-Regular';
        }

        .profile-menu .profile-menu-option {
            padding :2px 8px;
            min-height:28px
            }
            .profile-menu .MuiPaper-rounded ul {
                padding: 2px 0px;
            }
        .profile-menu .profile-menu-option p{
            font-size:13px;
            font-family: 'Poppins-Regular';

        }
        .account-select-list-menu ul li {
            min-height: 34px;
            font-size: 14px ;
        }

        .round-select-menu-items {
            min-height: 34px !important;
            font-size: 14px !important;
        }
        .dash-top-table .dash-top-table-head tr th{
         padding: 10px 20px;
        }

        .dash-top-table .dash-top-table-head tr th:nth-child(7),
        .dash-top-table .dash-top-table-head tr th:nth-child(8) {
         padding: 10px 35px;
        }

        .feature-heading-box .search-btn-box{
            display: flex;
         flex-direction: column;
       align-items: end;

        }
        .MuiMenu-list  li{
            min-height: 25px;
        }
        .login-section:after{
            z-index: -1;
        }

        .social-form-box .tokenomics-row {
            margin-bottom: 50px;
            border: 1px solid lightgray;
            padding: 8px;
            border-radius: 23px;
        }
         .account-pre-table-container {
            height: 400px;
        }

        .gradient-btn-main .gredient-btn,
        .gradient-btn-main .gradient-btn:hover,
        .border-btn-main .border-btn,
        .border-btn-main .border-btn:hover {
              font-size: 15px;
              line-height: 19px;
              padding: 10.39px 20px 10.71px;
        }
        .profile-icon-main .profile-icon {
            height: 40px;
            width: 40px;
        }
        .dash-top-table tr td{
            padding: 13px 5px;
        }

        .hero-one-slider-details,
        .one-tokenomics-left,
        .one-informationone-left{
            margin-left: 10px;
        }


      }


@media screen and (min-width: 320px) and (max-width:374px) {


    .main-connect .connect {
        width: 22px;
        height: 22px;
        margin-top: 8px;
      }

    .close-icon-phone{
        display: block;
    }


    .admin-main .admin-main-right {
        padding-left: 0px;
    }
  

      .icon-content-title-after .icon-title-info {
            font-size: 31px;
      }

      .icon-content-title-after .info-title-icon:after {
            width: 86px;
            height: 89px;
            background-size: 100% 100%;
      }

      .gradient-btn-main .gredient-btn,
      .gradient-btn-main .gradient-btn:hover,
      .border-btn-main .border-btn,
      .border-btn-main .border-btn:hover {
            font-size: 15px;
            line-height: 19px;
            padding: 10.39px 17px 10.71px;
      }

      .gradient-btn-main .hero-one-mint-btn,
      .gradient-btn-main .hero-two-mint-btn,
      .gradient-btn-main .hero-three-mint-btn {
            min-height: 42px;
      }

      .menu-icon-mobile {
            height: 20px;
      }


      .white-paper-input-box p {
            color: gray;
            margin: 9px 1px;
        }
        .social-form-box .tokenomics-row {
            display: flex;
            justify-content: center;
            align-items: end;
        }

        .select-box .select-round {
         margin-bottom: 3px;
        }
 
        .pre-history-heading-box .select-box {
            display: flex;
            flex-direction: column;
            gap: 2px;
        }

        .amount-error-box p {
            margin-left: 10px;
            color: red;
            font-size: 12px;
            font-family: "Poppins-Medium";
        }

        .presale-input-flex {
            flex-direction: column;
            gap: 20px;
        }

        
.my-account-heading-box .my-account-claim-btn {
      gap: 6px;
          flex-direction: column;
      }
      
      .my-account-claim-btn .select-round {
      min-width: 130px;
          max-width: 200px;
      }
      
      
      .my-account-claim-btn .my-account-search-input {
          max-width: 220px;
      }

      .account-main-div {
            margin-top: 88px;
            margin-bottom: 0px;
            height: 97vh;

      }
      .hero-one-slider-details {
            margin-bottom: 25px;
        }

        .account-page .my-accout-custom {
            overflow:hidden;
            }
            
            .my-account-heading-box .my-account-heading {
                  font-size: 20px;
                  line-height: 27px;
                  margin-left: 5px;
               }
        
              /* .wallet-adapter-dropdown button .wallet-adapter-button-start-icon{
                display: none;
              } */

              .container {
                  padding: 0px;
              }
              .header-row-common .header-right {
                  gap:5px;
              }
              .header-one-header-sec .header-logo-com .header-refreal-link .logo-brand-header {
                  min-width: 60px;
              }

              .white-paper-input-box .profile-input-field {
                  max-width: 104px;
              }

              .feature-one-main .feature-one-card-text {
                  overflow: auto;
                  height: 300px;
              }
              .account-pre-top-table thead tr th {
                  padding: 10px 24px;
                  display: flex;
                  justify-content: center;
                 align-items: center;
              }

              .my-accout-custom .MuiTablePagination-toolbar .css-rmmij8 ,
            .table-list-pagination .MuiTablePagination-toolbar .css-rmmij8 {
                  margin-right: 5px !important;
              }

              .my-accout-custom .MuiTablePagination-toolbar .MuiTablePagination-actions ,
                .table-list-pagination .MuiTablePagination-toolbar .MuiTablePagination-actions {
                  margin-left: 5px !important;
              }
              .my-accout-custom .MuiTablePagination-toolbar .MuiTablePagination-actions button,
              .table-list-pagination .MuiTablePagination-toolbar .MuiTablePagination-actions button {
              padding: 8px 3px !important;
              }
               

              .my-account-claim-btn .my-account-search-input {
                  max-width: 200px;
              }

              .my-account-claim-btn button {
                  padding: 7.3px 38px 7.7px;
              }

              .user-list-top-table .private-pre-table-head tr th {
                  padding: 10px 52px;
              }

              .private-pre-top-table .private-pre-table-head tr th:nth-child(3) {
                padding: 10px 80px;
            }

            .private-pre-top-table .private-pre-table-head tr th:nth-child(4) {
              padding: 10px 40px;
          }
              .feature-list-top-table .private-pre-table-head tr th:nth-child(3) {
                  padding: 10px 50px;
                 }
                 .feature-list-top-table .private-pre-table-head tr th:nth-child(4) {
                       padding: 10px 150px;
                      }
               .feature-list-top-table .private-pre-table-head tr th:nth-child(5) {
                       padding: 10px 20px;
                      }

                        .roadmap-list-top-table .private-pre-table-head tr th:nth-child(2) {
            padding: 10px 50px;

        }

        .roadmap-list-top-table .private-pre-table-head tr th:nth-child(3) {
            padding: 10px 120px;

        }
        .roadmap-list-top-table .private-pre-table-head tr th:nth-child(5) {
            padding: 10px 20px;

        }


                      
        .presale-userlist .feature-heading-box {
            gap: 35px;
        }

        .feature-heading-box .roadmap-add-btn,
        .feature-heading-box .feature-add-btn {
            margin-top: 5px;
        }

        .admin-progress-bar-text{
            font-family: 'Poppins-Regular';
        }

        .profile-menu .profile-menu-option {
            padding :2px 8px;
            min-height:28px
            }
            .profile-menu .MuiPaper-rounded ul {
                padding: 2px 0px;
            }
        .profile-menu .profile-menu-option p{
            font-size:13px;
            font-family: 'Poppins-Regular';

        }
        .account-select-list-menu ul li {
            min-height: 34px;
        }

        .round-select-menu-items {
            min-height: 34px !important;
            font-size: 14px !important;
        }

        .dash-top-table .dash-top-table-head tr th{
            padding: 10px 20px;
           }
   
           .dash-top-table .dash-top-table-head tr th:nth-child(7),
           .dash-top-table .dash-top-table-head tr th:nth-child(8) {
            padding: 10px 35px;
           }

           .feature-heading-box .search-btn-box{
            display: flex;
            flex-direction: column;
             align-items: end;
           }
        
        .MuiMenu-list li{
            min-height: 25px;
        }

        .login-section:after{
            z-index: -1;
        }

        .social-form-box .tokenomics-row {
            margin-bottom: 50px;
            border: 1px solid lightgray;
            padding: 8px;
            border-radius: 23px;
        }


    .account-pre-table-container {
            height: 375px;
        }

        .profile-icon-main .profile-icon {
            height: 40px;
            width: 40px;
        }

        .dash-top-table tr td{
            padding: 13px 5px;
        }

        .table-scroll .table-body-screen-box{
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 45vh;
          }

        
}