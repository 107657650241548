.feature-list-table-container .feature-list-top-table{
    overflow: scroll;
}


.feature-list-top-table .private-pre-table-head tr th {
    font-size: 13px;
    font-family: 'Poppins-Regular';
    color: #ffff;
    /* background-color: #3D117C; */
    padding: 10px 6px;
    
  }

  .feature-list-top-table tbody tr td {
    padding: 8px 12px;
    font-family: 'Poppins-Regular';
    font-size: 12px;

  }
  .feature-list-top-table .private-pre-table-head tr {
    background-image: linear-gradient(to right, #00ee92, #d91ffb);
  }


.feature-list-top-table tr th:nth-child(1) {
    border-top-left-radius: 16px;
  }
  
  .feature-list-top-table tr th:nth-child(9) {
    border-top-right-radius: 16px;
  }
  
  .feature-list-top-table tr th:nth-child(1),
  .feature-list-top-table tr td:nth-child(1) {
    width: 3%;
  }
  .feature-list-top-table tr th:nth-child(2),
  .feature-list-top-table tr td:nth-child(2) {
    width: 3%;

  }

  
  .dash-body-cell-box {
    border: 1px solid lightgray;
    height: 38px;
    border-radius: 6px;
  }

  
  .feature-list-top-table tr th:nth-child(3),
  .feature-list-top-table tr td:nth-child(3) {
    width: 8%;
    white-space: pre-wrap;
  }
  .feature-list-top-table tr th:nth-child(4),
  .feature-list-top-table tr td:nth-child(4) {
    width: 15%;
    white-space: pre-wrap;
  }

  .feature-list-top-table tr th:nth-child(5),
  .feature-list-top-table tr td:nth-child(5) {
    width: 4%;
    text-transform: capitalize;
    
  }
  
  
  .action-icon-box .delete-icon{
    cursor: pointer;
     color: red;
     margin-left: 8px;
  }

  .action-icon-box .edit-icon{
    cursor: pointer;
    color: blue;
    
  }


  .feature-heading-box .user-heading{
    font-family: 'Poppins-Medium';
    font-size: 18px;
    color: #240736;
}

.presale-userlist .feature-heading-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 10px 10px 10px;

}

.feature-heading-box .feature-add-btn{
    margin-left: 14px;
    border-radius: 8px;
    height: 33px;
    width: 128px;
    background-color: #445c96;
    color: #ffff;
    text-transform: capitalize;
    font-size: 13px;
    font-family: "Poppins-Regular";
    gap: 5px;
}

.feature-heading-box .feature-add-btn:hover{
    background-color: #445c96;
}


.feature-list-table-container {
    border-radius: 16px;
    border: 1px solid lightgray;
    /* border-bottom: transparent; */

}
.feature-list-table-container .user-list-top-table{
    overflow: scroll;
}


.feature-list-table-container {
    max-height: 440px; 
    height: 440px; 
    overflow-y: auto;  
  }
  .feature-list-table-container::-webkit-scrollbar {
    display: none;
  }

  .feature-list-img-box .feaure-list-img {
    width: 64px;
    height: 44px;
    border-radius: 8px ;
    object-fit: contain;
  }

  .admin-edit-feature-main .section-box {
    min-height: 340px;
    border: 1px solid lightgray;
    border-radius: 12px;
    margin-top: 20px;
  }
  
  .feature-heading .back-btn{
    margin-left: 8px;
    border-radius: 8px;
    height: 33px;
    width: 85px;
    background-color: #445c96;
    color: #ffff;
    text-transform: capitalize;
  }
  .feature-heading .back-btn:hover{
    background-color: #445c96;

  }


  .feature-form-box .icon-preview{
    width: 80px;
    height: 60px;
    border-radius: 15px;
    margin-left: 5px;
  }

 .edit-profile-content .feature-form-box{
  margin-top: 25px;

 }


.feature-heading-box .feature-add-btn svg{
font-size: 16px;
}

