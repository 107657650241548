:root {
    --black: #000000;
    --white: #ffffff;
    --pink: #ce3b9d;
    --blue: #2b5af3;
    --green: #38ff8e;
    --darkBlue: #040b28;
    --gray: #24293b;
    --br-gray: #47495a;
    --gradient:linear-gradient(to right, #00ee92, #d91ffb);
    --border-radius-5: 5px;
    --border-radius-15: 15px;
    --body-text: "Inter";
    --head-text: "violetsans";
    --w3m-accent: #2b5af3;
    --cardColor: #fffffff;
    --body-base: #f8f8f8;
    --box-shadow: 0 0 27px rgba(35, 31, 32, 0.05);
    --table-border: #d7d7d7;
    --input-border: #b4b4b4;
  }
  

.history-btn {
    cursor: pointer;
    background: linear-gradient(to right, #00ee92, #d91ffb);
    padding: 2px 12px;
    border-radius: 10px;
    font-size: 14px;
    color: white;
    margin-right: 10px;
    font-weight: 500;
    line-height: 30px;
    font-family: 'Poppins-Regular';
  }
  
  .history-btn span {
    margin: 0 10px;
  }
  
  .history-custom-btn-group {
    display: flex;
    /* justify-content: space-between; */
    /* Adjust this value if you need different spacing */
  }
  
  .history-wraper {
    width: 100%;
    padding: 15px;
  }
  
  .history-table-body .history-tr {
    margin-bottom: 10px;
  }
  
  .history-table-body .history-tr:last-child {
    margin-bottom: 0;
  }
  
  
  .history-cus-table .history-table-body {
    padding: 15px 27px;
    border: 1px solid pink;
    border-top: none;
    border-radius: 0 0 15px 15px;
  }
  
  .history-cus-table .history-table-head {
    background: linear-gradient(to right, #00ee92, #d91ffb);
    border-radius: 15px 15px 0 0;
    padding: 11px 42px 11px 42px;
    display: flex;
    /* justify-content: space-between; */
  }
  
  .history-cus-table .th-blank {
    text-align: center;
    width: 8%;
  }
  
  .history-cus-table .th-offe-type {
    text-align: center;
    width: 6%;
  }
  
  .history-cus-table .th-quantity {
    text-align: center;
    width: 15%;
  }
  
  .history-cus-table .th-offe-price {
    text-align: center;
    width: 15%;
  }
  
  .history-cus-table .th-offe-raise {
    text-align: center;
    width: 15%;
  }
  
  .history-cus-table .th-action {
    text-align: center;
    width: 15%;
  }
  
  .history-cus-table .history-th {
    color: #fff;
    font-family: "Poppins-Regular";
    font-size: 12px;
    line-height: 27px;
    min-width: 29px;
  }
  
  .history-tr .history-tr-main {
    padding: 8px 15px 8px 15px;
  }
  
  .history-tr-main .lock-main {
    width: 30px;
    height: 30px;
  }
  
  .history-list {
    width: 91%;
    display: flex;
    margin-left: 4%;
  }
  
  .history-tr .history-th-text {
    color: #ffff;
    font-family: "Poppins-Medium";
    font-size: 12px;
    line-height: 27px;
  }
  
  .history-list .history-td-box {
    height: 30px;
    width: 100%;
  }
  
  .history-list .inner-wrape {
    display: flex;
    width: 100%;
    gap: 88px;
  
    .th-quantity {
      width: 25%;
    }
  }
  
  .history-table-head .history-list .inner-wrape {
    padding: 0px 11px 0px 0;
  }
  
  
  .btn-like {
    background: linear-gradient(109deg, #FF387A 0%, #4845FF 100%) 0% 0%;
    border-radius: 15px;
    width: 100%;
    color: var(--white);
    font-size: 16px;
    line-height: 16px;
    font-family: "Poppins-medium";
    padding: 13.5px 10px;
    text-transform: capitalize;
  }
  
  .btn-like .history-th-text {
    line-height: 1.25;
    white-space: nowrap;
  }
  
  img.lock-ic {
    width: 14px;
    height: 14px;
  }
  
  .history-table-body .history-tr {
    margin-bottom: 10px;
  }
  
  .history-table-body .history-tr:last-child {
    margin-bottom: 0;
  }

  
.presale-wraper {
    width: 100%;
    padding: 5px;
    background:white;
  }

  
.stat-box {
    display: flex;
    width: 100%;
    gap: 30px;
  }
  
  .stat-box {
    display: flex;
    width: 100%;
    gap: 30px;
  }
  .stat-box .stat {
    width: calc(100% / 3);
    display: flex;
    border: 1px solid #efefef;
    border-radius: 16px;
    padding: 30px;
    flex-wrap: wrap;
    position: relative;
    background: rgba(255, 255, 255, 1);
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.12);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border: 1px solid rgba(255, 255, 255, 1);
    margin-bottom: 30px;
    justify-content: space-between;
    align-items: center;
  }
  .stat-box .p-currency {
    background: var(--blue);
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .stat.unlock .p-currency {
    background: var(--green);
  }
  .stat.available .p-currency {
    background: var(--black);
  }
  .stat-box .p-currency svg {
    height: 24px;
  }
  .stat-box h6 {
    font-size: 22px;
    font-weight: 600;
    font-family: "Poppins-regular";
    position: relative;
    color: #000000;
  }
  
  .stat-box .claim-lable {
    background: var(--blue);
    padding: 4px 15px 5px 15px;
    border-radius: 14px;
    display: inline-block;
    width: auto;
    position: absolute;
    right: 0;
    line-height: 1.25;
  }
  .stat-box .claim-lable span {
    color: var(--white);
    font-weight: 500;
    font-size: 13px;
  }
  .stat-box p {
    color: var(--black);
    opacity: 0.87;
    font-family: "Poppins-Regular";

  }
  .stat-box .text-wrape {
    width: calc(100% - 60px);
  }
  
  .date-new {
    width: 100% !important;
    margin-left: 2% !important;
    margin-bottom: 5% !important;
  }
  
  .custom-title-main {
    width: 100%;
    display: flex;
    padding-right: 10px;
    justify-content: space-between;
  }
  
  .custom-title-main .custom-main-select {
    padding: 5px;
  }
  
  .custom-main-select .MuiSelect-select {
    min-height: 20px;
    text-transform: capitalize;
    width: 210px;
    padding: 5px 15px 5px 5px;
  }
  
  


/* table-css */

.private-pre-table-container {
    border-radius: 16px;
    border: 1px solid lightgray;
    /* border-bottom: transparent; */

}
.private-pre-table-container .private-pre-top-table{
    overflow: scroll;
}


.private-pre-top-table .private-pre-table-head tr th {
    font-size: 13px;
    font-family: "Poppins-Regular";
    color: #ffff;
    /* background-color: #3D117C; */
    padding: 10px;
  }
  .private-pre-top-table .private-pre-table-head tr {
    background-image: linear-gradient(to right, #00ee92, #d91ffb);

  }

  .private-pre-top-table tbody tr td {
    font-family: 'Poppins-Regular';
    font-size: 12px;
    padding: 6px 10px;
  }

.private-pre-top-table tr th:nth-child(1) {
    border-top-left-radius: 16px;
  }
  
  .private-pre-top-table tr th:nth-child(9) {
    border-top-right-radius: 16px;
  }
  
  .private-pre-top-table tr th:nth-child(1),
  .private-pre-top-table tr td:nth-child(1) {
    width: 9%;
    font-size: 12px;
  }
  .private-pre-top-table tr th:nth-child(2),
  .private-pre-top-table tr td:nth-child(2) {
    width: 8%;
  }
  .private-pre-top-table tr td:nth-child(2) {
    font-size: 12px;
    font-family: "Poppins-Regular";
  }
  
  .dash-body-cell-box {
    border: 1px solid lightgray;
    height: 38px;
    border-radius: 6px;
  }

  
  .private-pre-top-table tr th:nth-child(3),
  .private-pre-top-table tr td:nth-child(3) {
    width: 15%;
    font-size: 12px;

  }
  .private-pre-top-table tr th:nth-child(4),
  .private-pre-top-table tr td:nth-child(4) {
    width: 15%;
    font-size: 12px;
  }
  .private-pre-top-table tr th:nth-child(5),
  .private-pre-top-table tr td:nth-child(5) {
    width: 8%;
    font-size: 12px;

  }
  .private-pre-top-table tr th:nth-child(6),
  .private-pre-top-table tr td:nth-child(6) {
    width: 10%;
    font-size: 12px;
  }
  


  .view-icon-box svg{
    background: blue;
    padding: 3px;
    color: #fff;
    border-radius: 35%;
    cursor: pointer;
    width: 28px;
    height: 24px;
  }


  .cus-main-modal .cus-modal {
    width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 15px;
    outline: none;
    padding: 5px;
  }

  .sale-modal-heading-box{
    display: flex;
    color: #000000;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    padding: 10px;

  }

  .sale-modal-heading-box .heading-text{
    color: blue;
    font-size: 20px;
    font-family: "Poppins-medium";
  }

  .close-icon-box .close-icon{
    background-color: #00ee92;
    color: #ffff;
    border-radius: 12px;
    padding: 3px;
    cursor: pointer;
  }

  .cus-modal .modal-view-table{
    padding: 10px;

  }
  .modal-view-table .modal-view-head{
    background-color: #000000;
    display: flex;
    /* justify-content: space-around; */
    border-radius: 10px;
    align-items: center;
    font-size: 12px;
    padding: 5px 0px;
    margin-bottom: 10px;
    gap: 88px;
    margin-left: 10p;
    padding-left: 30px;
        color: white;
    font-family: 'Poppins-Regular';
  }

  .modal-view-head p{
    font-size: 12px;
  }

  .modal-view-table .modal-view-body{
    background-color: #00af6c;
    display: flex;
    justify-content: space-around;
    border-radius: 5px;
    align-items: center;
    font-size: 12px;
    padding: 7px 0px;
    margin-top: 5px;
  }

  .modal-view-body p{
    font-size: 12px;
  }
  

  .cus-modal .sale-mdoal-middle-box{
    display: flex;
    justify-content: space-between;
    padding: 10px 20px ;
    gap: 20px;
  }
 .sale-mdoal-middle-box .heading-label{
    display: flex;
  }

  .sale-mdoal-middle-box .heading-label .heading{
    font-size: 14px;
    font-family: "Poppins-regular";
   color: #000000;
  }

  .sale-mdoal-middle-box .heading-label .text{
  color: gray;
  font-size: 13px;
  margin-left: 5px;
  font-family: "Poppins-regular";

  }

  
  .cus-modal .sale-mdoal-middle-box-first{
    display: flex;
    padding: 10px 20px ;
    gap: 33%;
  }
 .sale-mdoal-middle-box-first .heading-label{
    display: flex;
  }

  .sale-mdoal-middle-box-first .heading-label .heading{
    font-size: 14px;
    font-family: "Poppins-regular";
   color: #000000;
  }

  .sale-mdoal-middle-box-first .heading-label .text{
  color: gray;
  font-size: 13px;
  margin-left: 5px;
  font-family: "Poppins-regular";

  }


  .modal-view-table .modal-view-body-main{
    overflow: auto;
    max-height: 240px;
  }

  .modal-view-table .modal-view-body-main::-webkit-scrollbar {
    display: none;
  }


  .presale-wraper {
    width: 100%;
    overflow-x: auto;
  }
  
  .private-pre-table-container {
    max-height: 428px;
    overflow-y: auto;  
  }
  .private-pre-table-container::-webkit-scrollbar {
    display: none;
  }

  .private-pre-table-head {
    position: sticky;
    top: 0;
    background-color: white; 
    z-index: 1;
  }
  
  .view-icon-box {
    cursor: pointer;
  }
  
    .copy-icon-box{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .copy-icon-box svg {
    margin-left: 5px;
    padding: 5px;
    cursor: pointer;
  }
  
  .copy-success{
    font-size: 11px;
  color: #000000;
  }

  .pre-history-heading-box p{
    font-family: 'Poppins-Medium';
    font-size: 18px;
    /* margin: 0px 0px 10px 10px; */
    color: #240736;
  }

 .presale-wraper .pre-history-heading-box {
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  margin-right: 5px;
 }

 .pre-history-heading-box .select-box{
  gap: 20px;
 }

 .select-box .select-round{
  margin-right: 12px;
 }

 .select-box .select-round .MuiOutlinedInput-root:hover {
  border: 1px solid lightgray;
  width: 175px;
  font-family: 'Poppins-Regular';
  font-size: 12px;
  height: 32px;
  padding: 0px 5px;

 }

 .select-box .select-round .MuiOutlinedInput-root{
  font-family: 'Poppins-Regular';
  font-size: 12px;
  height: 32px;
  width: 175px;
  padding: 0px 5px;

 }

 .select-box .select-round .Mui-focused .MuiOutlinedInput-notchedOutline{
  border: 1px solid lightgray;
 }

 
.round-select-menu-items {
  font-family: 'Poppins-Regular' !important;
  font-size: 12px !important;
}
