* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

/* body {
  color: var(--main-white);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  font-family: "Poppins-Regular";
} */

:root {
  --main-white: #ffffff;
  --main-primary: #fff;
  --main-purple: #3d117c;
  --main-purple-dark: #250657;
  --main-secondary: #ffffff33;
  --main-black: #000000;
  --main-gray: #8a8591;
  --main-purple-light: #3c1c68;
  --main-black-light: #222222;
  --main-gray-middle: #666666;
  --main-white-light: #ebebeb;
  --main-blue: #0257bb;
  --main-black-middle: #000d1c;
  --main-gray-dark: #ffffff19;
}

@font-face {
  font-family: "Poppins-Light";
  src: url(../fonts/Poppins/Poppins-Light.ttf);
}

@font-face {
  font-family: "Poppins-Regular";
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Poppins-Bold";
  src: url(../fonts/Poppins/poppins-bold.ttf);
}

@font-face {
  font-family: "Poppins-Medium";
  src: url(../fonts/Poppins/Poppins-Medium.ttf);
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url(../fonts/Poppins/Poppins-SemiBold.ttf);
}

.grid-main .grid-column {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
}

.container {
  margin: auto;
  max-width: 1440px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.text-decoration-none {
  text-decoration: none !important;
}

.flex-all {
  display: flex;
  justify-content: space-between;
}

.form-group .form-control input {
      border: 0;
      width: 100%;
      padding: 14.29px 40px 12.71px;
      background-color: #FFFFFF1A;
      border-radius: 10px;
      font-size: 16px;
      line-height: 25px;
      color: var(--main-white-light);
      font-family: 'Poppins-Regular';
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      /* border: 1px solid var(--main-white);
  width: 100%;
  padding: 14.29px 40px 12.71px;
  border-radius: 25px;
  font-size: 16px;
  line-height: 25px;
  font-family: "Poppins-Regular"; */
}

.form-group .form-control input::placeholder {
  font-size: 16px;
  line-height: 25px;
  font-family: "Poppins-Regular";
  opacity: 1;
}

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
  border-width: 0px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.form-control-textarea:focus-visible {
  outline: none !important;
}

fieldset {
  border: 0px;
}

.gradient-btn-main .gredient-btn,
.gradient-btn-main .gradient-btn:hover {
  background: transparent;
  border-radius: 25px;
  font-family: "Poppins-Regular";
  font-size: 16px;
  line-height: 23px;
  color: var(--main-white);
  text-transform: capitalize;
  box-shadow: 3px 3px 0 0 #01faa3;
  border: 1px solid var(--main-white);
  padding: 14.39px 40px 12.71px;
}

.border-btn-main .border-btn,
.border-btn-main .border-btn:hover {
  font-size: 16px;
  line-height: 25px;
  box-shadow: 3px 3px 0 0 #01faa3;
  border: 1px solid var(--main-white);
  border-radius: 25px;
  background-color: transparent;
  font-family: "Poppins-Regular";
  color: var(--main-white);
  text-transform: capitalize;
  font-size: 16px;
  padding: 14.3px 40px 12.7px;
  line-height: 25px;
}

.white-btn-main .white-btn,
.white-btn-main .white-btn:hover {
  background: var(--main-white) 0% 0% no-repeat padding-box;
  border-radius: 25px;
  font-family: "Poppins-Regular";
  color: var(--main-black);
  text-transform: capitalize;
  padding: 5px 24px 5px;
  font-size: 14px;
  min-height: 40px;
  line-height: 20px;
}

.common-para {
  font-size: 14px !important;
  line-height: 21px !important;
  font-family: "Poppins-Regular";
}

.title-gradient {
  background: -webkit-linear-gradient(
    145deg,
    #d91ffb,
    #00ee92 42%,
    #d91ffb 100%
  );
  background: linear-gradient(119deg, #d91ffb, #00ee92 2%, #d91ffb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.display-flex {
  display: flex;
}

.position-relative {
  position: relative;
}

.main-title .title-gradient {
  line-height: 60px;
  font-size: 52px;
  font-family: "poppins-bold";
}

/* -------------------maulik----------------- */

.recent-news-row {
  margin-left: -15px;
  margin-right: -15px;
}

.recent-news-row > * {
  padding: 0px 15px;
}

.readmore-btn-main .readmore-btn,
.readmore-btn-main .readmore-btn:hover {
  background: #3d117c 0% 0% no-repeat padding-box;
  border-radius: 50px;
  font-family: "Poppins-Light";
  color: var(--main-primary);
  text-transform: capitalize;
  padding: 6px 20px;
  font-size: 12px;
  line-height: 18px;
  border: 1px solid #581ab1;
}

.view-more-sec {
  text-align: center;
}

/* comman light page css start*/
.icon-content-title-after .icon-title-info {
  color: var(--main-black-light);
  font-size: 38px;
  line-height: 60px;
  font-family: "Poppins-Bold";
  z-index: 2;
  position: relative;
  display: inline-block;
}

.icon-content-title-after .info-title-icon {
  position: relative;
  display: inline-block;
}

.icon-content-title-after .info-title-icon:after {
  background-image: url("../images/svg/light-title-icon.svg");
  width: 93px;
  height: 99px;
  content: "";
  position: absolute;
  top: -27px;
  left: -35px;
  z-index: 1;
  animation: iconpulse 1s infinite ease-in-out alternate;
  opacity: 0.2;
}

@keyframes iconpulse {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}

/* comman light page css end */

/* start progrss bar css */

.admin-progress-bar-main {
  max-width: 470px;
  width: 100%;
  position: relative;
}

.admin-progress-bar-main .admin-progress-bar .MuiLinearProgress-bar {
  border-radius: 8px;
  background-color: #01faa3;
}

.admin-progress-bar-content {
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 350px; */
}

.admin-progress-bar-content .admin-page-title {
  font-size: 20px;
  line-height: 30px;
  color: var(--primary-color);
  font-weight: 500;
}

.admin-progress-bar-text {
  position: absolute;
  right: -51px;
  top: -8px;
  font-size: 14px;
  line-height: 21px;
  color: var(--main-white);
  font-weight: 500;
}

/* end progrss bar css */

/* model */
.modal-common-main .modal-common-style {
  /* background: #fff; */
  background-color: #240736;
  /* background: linear-gradient(to right, #240736, #bcadc5); */
  border: 0;
  border-radius: 20px;
  max-width: 746px;
  overflow: hidden;
  padding: 30px 15px;
  width: calc(100% - 30px);
}

.modal-hgt-scroll {
  max-height: 550px;
  overflow: auto;
  padding: 0 15px;
}
.modal-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.comman-form-group .comman-form-control input {
  /* background-color: #fff; */
  border: 1px solid #dfe0e1;
  border-radius: 5px;
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 15px;
  padding: 9px 15px;
  width: 100%;
  color: white;
}

.comman-form-group .comman-form-control fieldset {
  border: none;
  border-radius: 5px;
}
.comman-form-group .custom-password .MuiOutlinedInput-root{
  border: 1px solid #dfe0e1;
}
.comman-form-group .custom-password input{
  border: none !important;
}

/* user choose wallet model start */
.choose-wallet-details .choose-main-wallet-title {
  color: #FFFFFF;
  text-align: center;
  font-family: 'nunito-bold', sans-serif;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 20px;
}

.wallet-flex-content {
  display: flex;
  /* align-items: center; */
  justify-content: start;
}

.btn-wallet-main .btn-wallet-content,
.btn-wallet-main .btn-wallet-content:hover {
  background: #FFFFFF12;
  border-radius: 8px;
  width: 100%;
  justify-content: start;
  cursor: pointer;
  padding: 8px;
}

.btn-wallet-main {
  margin-bottom: 15px;
}

.wallet-title-details {
  text-align: start;
}

.wallet-title-details .metamask-title-wallet {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 19px;
  font-family: 'nunito-bold', sans-serif;
  text-transform: capitalize;
}

.wallet-title-details .metamask-details-wallet {
  color: #FFFFFF;
  font-size: 10px;
  line-height: 14px;
  font-family: 'nunito-regular', sans-serif;
  text-transform: initial;
}

.btn-wallet-main .btn-wallet-content .wallet-main-content {
  margin-right: 6.66px;
  /* height: 30px; */
  /* width: 30px; */
  height: 35px;

  width: 35px;
  border-radius: 50%;
}

.wallet-btn-details .btn-wallet-main:last-child {
  margin-bottom: 0px;
}
/* user choose wallet model end */


.pagenotfound-text-heading {
  margin-top: 10px !important;
  font-size: 20px !important;
  font-weight: bold !important;
}
.pagenotfound-img {
  margin-top: 10px !important;
  width: 80% !important;
  height: 70% !important;
  margin-left: 10% !important;
}

.pagenotfound-text-heading {
  text-align: center !important;
}
.pagenotfound-text {
  text-align: center !important;
}


.wallet-adapter-dropdown button{
  font-size: 16px;
  line-height: 25px;
  box-shadow: 3px 3px 0 0 #01faa3;
  border: 1px solid var(--main-white);
  border-radius: 25px;
  background-color: transparent;
  background-color: #1a1f2e;
  font-family: "Poppins-Regular";
  color: var(--main-white);
  text-transform: capitalize;
  font-size: 16px;
  padding: 14.3px 40px 12.7px;
  line-height: 25px;
}


 /* delete-modal */

 .cus-main-modal .cus-modal {
  width: 480px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 15px;
  outline: none;
  padding: 50px;
}

.cus-modal .modal-heading-text {
  font-size: 30px;
  text-align: center;
  font-family: "Poppins-Bold";

}

.cus-modal .modal-text {
  margin: auto;
  margin-top: 20px;
  max-width: 340px;
  font-size: 16px;
  text-align: center;
  color: gray;
  font-family: "Poppins-Regular";

}

.first-cancel-btn button {
  width: 120px;
  height: 43px;
  color: #fff;
  font-size: 15px;
  border-radius: 20px;
  background-color: #445c96;
  font-size: 14px;
  font-family: "Poppins-medium";
  text-transform: capitalize;
}

.second-delete-btn button {
  width: 120px;
  height: 43px;
  color: #fff;
  font-size: 14px;
  border-radius: 20px;
  margin-left: 15px;
  background-color: #445c96;;
  /* background-color: #10283d; */
  font-size: 15px;
  font-family: "Poppins-medium";
  text-transform: capitalize;
}

.first-cancel-btn button:hover {
  background-color: #445c96;;
}

.second-delete-btn button:hover {
  background-color: #445c96;;
}

.main-button-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}



.admin-sidebar-list::-webkit-scrollbar {
  width: 8px; 
}

.admin-sidebar-list::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.admin-sidebar-list::-webkit-scrollbar-thumb {
  background: #4a0574; 
  border-radius: 10px; 
}

.admin-sidebar-list::-webkit-scrollbar-thumb:hover {
  background: #4a0574; 
}

.user-search-input .Mui-focused .MuiOutlinedInput-notchedOutline{
  border: none;
 }

 .main-connect .connect {
  width: 25px;
  height: 25px;
  border-radius: 8px;
  margin-top: 7px;
  object-fit: contain;
}

 .wallet-adapter-dropdown button .wallet-adapter-button-start-icon{
  display: none;
}

.custom-header-right .wallet-adapter-dropdown button{
  padding: 13.3px 28px 12.7px;
  gap: 8px;
}

 
