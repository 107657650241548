.informationone-main{
    padding: 100px 85px;
    background-color: #240736;
    position: relative;
    overflow: hidden;
}

.informationone-main:before {
    background: linear-gradient(174deg, #d91ffb, #00ee92);
    filter: blur(104px);
    height: 450px;
    right: 0;
    bottom: 20%;
    width: 431px;
    content: '';
    position: absolute;
    opacity: 0.3;
}
.informationone-main::after {
    background: linear-gradient(174deg, #d91ffb, #00ee92);
    filter: blur(104px);
    height: 450px;
    left: 0;
    bottom: 0;
    width: 431px;
    content: '';
    position: absolute;
    opacity: 0.3;
}
.one-informationone-row {
    margin-left: -15px;
    margin-right: -15px;
    align-items: center;
}

.one-informationone-row>* {
    padding: 0px 15px;
    position: relative;
}
.one-informationone-title .title-gradient {
    font-size: 52px;
    line-height: 60px;
    font-family: 'poppins-bold';
    margin-bottom: 50px;
}

.one-informationone-left .one-informationone-para{
    font-size: 18px;
    font-weight: 400;
    line-height: 35px;
    font-family: 'Poppins-Regular';
    color: var(--main-primary);
    display: -webkit-box;
    -webkit-line-clamp: 12;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 20px;
}

.one-informationone-left .info-sub-title{
    font-size: 32px;
    line-height: 48px;
    color: var(--main-white);
    font-family: 'Poppins-Medium';
    margin-bottom: 20px;
    min-height: 48px;
}
.one-informationoneimg-img-sec{
    text-align: end;
}
.one-informationoneimg-img-sec .one-informationoneimg-img {
    animation: imgBounce 5s linear infinite;
    width: 480px;
    /* height: 512px; */
    position: relative;
    z-index: 9;
}
.house-img {
    height: 500px;
    width: 500px;
    object-fit: contain;
    position: absolute;
    left: -145px;
    top: 0;
    opacity: 0.3;
    z-index: 1;
}

@keyframes imgBounce {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(-20px);
	}
	100% {
		transform: translateY(0px);
	}
}



@media screen and (min-width: 1200px) and (max-width: 1299px) {}

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {
    
    .informationone-main {
        padding: 80px 0px;
    }
    .one-informationone-title .title-gradient {
        font-size: 50px;
        line-height: 55px;
        margin-bottom: 50px;
    }

}

/* phone and tablets */
@media screen and (max-width: 991px) {
  
}

/* tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {
    .house-img {
        left: 0;
        height: 422px;
    width: 471px;
    }
    .one-informationone-title .title-gradient {
        font-size: 40px;
        line-height: 42px;
        margin-bottom: 40px;
    }
    .info-sub-title {
        font-size: 28px;
        line-height: 44px;
    }
    .informationone-main {
        padding: 80px 0px;
    }
    .one-informationone-left .info-sub-title {
        font-size: 30px;
        line-height: 46px;
        margin-bottom: 15px;
    }

}

/* phone */
@media (max-width: 767px) {

    
    .house-img {
        height: 439px;
        width: 330px;
        left: 0px;
        top: 0;
        right: 0;
        margin: auto;
    }
    .informationone-main {
        padding: 50px 0px;
    }
    .one-informationone-title .title-gradient {
        font-size: 30px;
        line-height: 32px;
        margin-bottom: 40px;
    }
    .one-informationone-left .info-sub-title {
        font-size: 22px;
        line-height: 24px;
        color: var(--main-white);
        font-family: 'Poppins-Medium';
        margin-bottom: 10px;
        min-height: 24px;
    }
    .one-informationoneimg-img-sec .one-informationoneimg-img{
        width: 100%;
    }
    .one-informationoneimg-img-sec{
        margin-top: 100px;
    }
}
@media screen and (min-width: 320px) and (max-width:374px) {

}

.info-description-box span{
    font-size: 16px;
    font-weight: 400;
    line-height: 35px;
    font-family: 'Poppins-Regular';
    color: #ffff;
    cursor: pointer;
}