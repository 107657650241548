.featureone-main {
    position: relative;
    min-height: 900px;
    background-color: #240736;
    overflow: hidden;
    background-image: url(../../../../../assets/images/png/Roadmap-2-bg.png);

}

.one-featureone-title .title-gradient {
    font-size: 52px;
    line-height: 60px;
    font-family: 'poppins-bold';
    margin-bottom: 103px;
}

.one-feature-bg {
    padding: 100px 0px;
}

.one-featureone-content {
    position: relative;
}

.feature-one-main {
    padding: 50px 30px;
    min-height: 567px;
    background: #240736;
    border: 2px solid #00ee92;
    border-radius: 20px;
    transition: border 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.feature-one-main:hover {
    border-width: 3px;
    border-color: #d91ffb;
}

.one-feature-img-box {
    -webkit-animation: feature-onefull-circle 2.1s ease-out;
    animation: feature-onefull-circle 2.1s ease-out;
    
}

.one-feature-img-box .one-feature-img {
    width: 90px !important;
    height: 90px;
    object-fit: contain;
}


.one-feature-img-box {
    margin: 0 0 40px 0;
    display: flex;
    justify-content: center;
}

.feature-one-main .feature-one-card-title {
    font-size: 20px;
    line-height: 30px;
    font-family: 'Poppins-Medium';
    color: #00ee92;
    text-align: center;
    margin: 0 0 20px 0;
    display: -webkit-box;
    /* -webkit-line-clamp: 1; */
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 30px;
}

.feature-one-main .feature-one-card-text {
    max-width: 355px;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    font-family: 'Poppins-Regular';
    color: var(--main-primary);
    text-align: center;
    display: -webkit-box;
    /* -webkit-line-clamp: 7; */
    -webkit-box-orient: vertical;
    min-height: 146px;
        /* overflow: auto;
    height: 254px; */
}

.feature-one-caraousel-main .feature-one-owl-carousel .owl-nav [class*=owl-] {
    border-radius: 50%;
    width: 70px;
    height: 70px;
    background-color: #FFFFFF1A;
    margin: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.feature-one-caraousel-main .feature-one-owl-carousel .owl-nav [class*=owl-]:hover {
    background: linear-gradient(119deg, #d91ffb, #00ee92 2%, #d91ffb);
    color: var(--main-white);
    text-decoration: none;
    opacity: 0.3;
}

.feature-one-caraousel-main .feature-one-owl-carousel .owl-prev,
.feature-one-caraousel-main .feature-one-owl-carousel .owl-next {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.feature-one-caraousel-main .feature-one-owl-carousel .owl-prev img,
.feature-one-caraousel-main .feature-one-owl-carousel .owl-next img {
    background-color: transparent;
}

.feature-one-caraousel-main .feature-one-owl-carousel .owl-nav .owl-prev {
    left: -4.6%;
    top: 50%;
    transform: translateY(-50%);
}

.feature-one-caraousel-main .feature-one-owl-carousel .owl-nav .owl-next {
    right: -4.6%;
    top: 50%;
    transform: translateY(-50%);
}

@media screen and (min-width: 1281px) {
    .featureone-main .feature-round-img {
        left: -20%;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1299px) {}

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {

    .featureone-main {
        min-height: 700px;
    }

    .one-featureone-title .title-gradient {
        font-size: 50px;
        line-height: 55px;
        margin-bottom: 50px;
    }

    .one-feature-img-box {
        margin: 0 0 20px 0;
    }

    .feature-one-main .feature-one-card-title {
        margin: 0 0 15px 0;
    }

    .feature-one-caraousel-main .feature-one-owl-carousel .owl-nav .owl-next {
        left: -6.6%;
    }

    .feature-one-caraousel-main .feature-one-owl-carousel .owl-nav .owl-prev {
        right: -6.6%;
    }

    .feature-triangle2-img {
        bottom: 14%;
        right: 1%;
    }
}

/* phone and tablets */
@media screen and (max-width: 991px) {}

/* tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {
    .one-feature-bg {
        padding: 80px 0px 0 0px;
    }

    .feature-one-caraousel-main .feature-one-owl-carousel .owl-nav [class*=owl-] {
        width: 60px;
        height: 60px;
        margin: 0;
    }

    .one-featureone-title .title-gradient {
        font-size: 40px;
        line-height: 42px;
        margin-bottom: 40px;
    }

    .supported-one-caraousel-main {
        padding: 50px 50px;
    }

    .supported-one-main {
        min-width: 130px;
    }
}

/* phone */
@media (max-width: 767px) {
    .featureone-main{
        min-height: 760px;
    }
    .one-feature-bg {
        padding: 50px 0px 0 0px;
        height: 267px;
    }

    .one-featureone-title .title-gradient {
        font-size: 30px;
        line-height: 32px;
        margin-bottom: 40px;
    }

    .feature-one-main {
        padding: 35px 20px;
    }

    .feature-one-caraousel-main .feature-one-owl-carousel .owl-nav [class*=owl-] {
        width: 40px;
        height: 40px;
        margin: 0;
    }

    .feature-one-caraousel-main .feature-one-owl-carousel .owl-nav [class*=owl-] img {
        width: 12px;
        height: 12px;
    }

    .supported-three-main-title .supported-three-heading {
        line-height: 32px;
        font-size: 30px;
        margin-bottom: 20px;
    }

    .feature-one-caraousel-main .feature-one-owl-carousel .owl-nav [class*=owl-] {
        width: 30px;
        height: 30px;
    }

    .feature-one-caraousel-main .feature-one-owl-carousel .owl-nav [class*=owl-] img {
        width: 10px;
        height: 10px;
    }

    .feature-one-caraousel-main .feature-one-owl-carousel .owl-nav .owl-prev {
        right: -3.6%;
        left: initial;
    }

    .feature-one-caraousel-main .feature-one-owl-carousel .owl-nav .owl-next {
        left: -3.6%;
    }
}

.feature-one-card-text span{
    font-size: 12px;
    font-weight: 400;
    line-height: 35px;
    font-family: 'Poppins-Regular';
    color: #ffff;
    cursor: pointer;
}