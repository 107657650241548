@import url("/src/assets/style/style.css");

.header-light-container .header-comman-main {
    min-height: 103px;
    z-index: 9;
    position: relative;
    padding: 30px 0px;
}

.header-row-common {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-list-light-details .header-list-light {
    display: flex;
    align-items: center;
    padding: 0px;
}

.header-list-light-details .header-list-light .header-listitem-light .list-header-link {
    color: var(--main-white);
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    font-family: 'Poppins-SemiBold';
    position: relative;
}

.header-list-light-details .header-list-light .header-listitem-light:last-child {
    padding-right: 0px;
}

.light-gredient-btn .gradient-btn-main .gredient-btn,
.light-gredient-btn .gradient-btn-main .gredient-btn:hover {
    background: transparent linear-gradient(180deg, #3CADD8 0%, #091B9C 100%) 0% 0% no-repeat padding-box;
    padding: 10px 21px;
    font-size: 14px;
    line-height: 21px;
    min-width: 112px;
}

.header-list-light-details .header-list-light .header-listitem-light .list-header-link:after {
    content: '';
    background: #fff;
    width: 0px;
    height: 2px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -8px;
    border-radius: 10px;
    transition: width .3s;
}

.header-list-light-details .header-list-light .header-listitem-light:hover .list-header-link:after {
    width: 100%;
}

.header-inner-content-main {
    position: absolute;
    background: white;
    min-width: 674px;
    left: -113%;
    right: 0;
    top: 47px;
    box-shadow: 0px 20px 30px #00000047;
    padding: 30px;
    border-radius: 10px 10px 10px 10px;
}

.header-inner-content-main .header-flex-inner-content {
    display: flex;
    flex-wrap: wrap;
}

.header-inner-content-main .header-flex-inner-content .header-content-list-box {
    min-width: 168px;
    margin-right: 15px;
}

.header-inner-content-main .header-flex-inner-content .header-content-list-box .title-inner-header-list {
    font-family: 'Poppins-Medium';
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 20px;
}

.header-inner-content-main .header-flex-inner-content .light-image-headercontent .title-inner-header-list {
    font-family: 'Poppins-Medium';
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 8px;
}

.image-content-inner-header .img-show-inner-box .inner-img-header-content {
    max-width: 244px;
    width: 100%;
    border-radius: 11px;
    border: 1px solid #666464;
    margin-bottom: 0px;
    height: 152px;
    object-fit: cover;
}

.image-content-inner-header .header-show-img-light-content {
    font-size: 14px;
    font-family: 'Poppins-Light';
    max-width: 244px;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 15px;
}

.image-content-inner-header .learn-more-header-list {
    color: var(--main-black);
    text-decoration: none;
    font-family: 'Poppins-Light';
    font-size: 14px;
    line-height: 20px;
}

.image-content-inner-header .learn-more-header-list .header-arrow-right {
    width: 13px;
    height: 9px;
    margin-left: 6px;
}

.header-inner-content-main .header-flex-inner-content .header-content-list-box .header-inner-light-list {
    padding: 0px;
}

.header-inner-content-main .header-flex-inner-content .header-content-list-box .header-inner-light-list .header-inner-listitem-light {
    padding: 0px;
    margin-bottom: 20px;
}

.header-inner-content-main .header-flex-inner-content .header-content-list-box .header-inner-light-list .header-inner-listitem-light a {
    text-decoration: none;
    font-size: 16px;
    line-height: 23px;
    font-family: 'Poppins-Light';
    color: var(--main-black);
}

.header-inner-content-main .header-flex-inner-content .header-content-list-box .header-inner-light-list .header-inner-listitem-light:last-child {
    margin-bottom: 0px;
}

.header-onclick-inner-show {
    display: none;
}

.burgur-btn-mobile {
    display: none;
}

.mobile-show {
    display: none;
}

/* headerone css  */
.header-one-header-sec {
    z-index: 999;
    position: relative;
}

.header-one-header-sec .header-comman-main {
    padding: 10px 0px;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    background-color: #240736;
}

.header-one-header-sec .header-logo-com .header-refreal-link .logo-brand-header {
    height: 100px;
    min-width: 100px;
}


.header-one-header-sec .header-list-light-details .header-list-light .header-listitem-light {
    padding: 0px 25px;
    position: relative;
    max-width: max-content;
}

.header-one-header-sec .header-list-light-details .header-list-light .header-listitem-light .list-header-link:after {
    content: '';
    background-image: linear-gradient(119deg, #d91ffb, #00ee92 2%, #d91ffb);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.header-one-header-sec .header-one-btn-listitem {
    display: none;
}

/* end header one css */




/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {

}

/* phone and tablets */
@media screen and (max-width: 991px) {
    .header-list-light-details {
        z-index: 99;
        position: fixed;
        top: 0;
        left: -250px;
        background: #fff;
        bottom: 0;
        min-width: 250px;
        transition: 0.5s all;
    }

    .header-one-header-sec .header-logo-com .header-refreal-link .logo-brand-header {
        height: 55px;
        min-width: 50px;
    }

    .header-list-light-details .header-list-light {
        flex-direction: column;
        overflow: auto;
        height: 100vh;
        align-items: flex-start;
    }

    .header-list-light-details .header-list-light .header-listitem-light {
        padding: 10px 15px;
        flex-direction: column;
        align-items: start;
    }

    .header-list-light-details .header-list-light .header-listitem-light .list-header-link {
        color: var(--main-black);
    }

    .header-list-light-details .header-list-light .header-listitem-light .list-header-link:after {
        display: none;
    }

    /* .overlay-section {
        position: fixed!important;
        right: 0;
        max-width: 100%!important;
        background: rgb(20 19 19 / 50%);
        top: 0!important;
        bottom: 0;
        width: 100%!important;
        transition: all 0.3s!important;
        z-index: 9;
    }
    .mobile-show.overlay-mobile-active {
        display: block;
    } */
    .header-override {
        overflow: hidden;
    }

    .burgur-btn-mobile {
        display: block;
    }

    .burgur-btn-mobile .gredient-btn-menu {
        background: transparent linear-gradient(180deg, #3CADD8 0%, #091B9C 100%) 0% 0% no-repeat padding-box;
        padding: 0px;
        min-width: 46px;
        min-height: 45px;
        border-radius: 50%;
    }

    .header-light-container .header-comman-main {
        min-height: 78px;
        padding: 15px 0px;
    }

    .hero-light-container {
        margin-top: -78px;
    }

    .hero-light-container .hero-light-details {
        padding-top: 0px;
    }

    .hero-light-details .hero-mb40px {
        margin-bottom: 30px;
    }

    .hero-light-details .hero-light-standard {
        font-size: 29px;
        line-height: 45px;
    }

    .header-inner-content-main .header-flex-inner-content .header-content-list-box {
        min-width: 100%;
        margin: 15px 0px 0px 0px;
    }

    .header-inner-content-main .header-flex-inner-content .header-content-list-box .title-inner-header-list,
    .header-inner-content-main .header-flex-inner-content .header-content-list-box .header-inner-light-list .header-inner-listitem-light a {
        font-size: 14px;
        line-height: 21px;
    }

    .header-inner-content-main .header-flex-inner-content {
        flex-direction: column;
    }

    .header-inner-content-main {
        position: initial;
        background: transparent;
        min-width: 100%;
        box-shadow: none;
        padding: 0px;
        border-radius: 0px;
        padding-left: 20px;
    }

    .light-image-headercontent {
        display: none;
    }

    .header-list-light-details.header-left-active {
        left: 0px;
    }

    /* headerone res start */

    .header-one-header-sec .header-list-light-details .header-list-light .header-listitem-light {
        padding: 10px 15px;
        flex-direction: column;
        align-items: start;
    }

    .header-one-header-sec .burgur-btn-mobile .gredient-btn-menu {
        background: linear-gradient(119deg, #d91ffb, #00ee92 2%, #d91ffb);
        padding: 0px;
        min-width: 40px;
        min-height: 40px;
        border-radius: 50%;
    }

    .menu-icon-mobile {
        height: 17px;
        width: 17px;
        object-fit: contain;
    }

    .header-one-header-sec .header-list-light-details {
        background-color: #240736;
    }

    .header-one-header-sec .header-list-light-details .header-list-light .header-listitem-light .list-header-link {
        color: var(--main-white);
    }

    /* headerone res end */
    .gradient-btn-main .gredient-btn, .gradient-btn-main .gradient-btn:hover{
        padding: 10px;
    }
}

/* tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {}

/* phone */
@media (max-width: 767px) {
    .header-one-header-sec .header-logo-com .header-refreal-link .logo-brand-header {
        height: 70px;
        min-width: 80px;
    }
    .header-one-header-sec .burgur-btn-mobile .gredient-btn-menu {
        background: linear-gradient(119deg, #d91ffb, #00ee92 2%, #d91ffb);
        padding: 0px;
        min-width: 35px;
        min-height: 35px;
        border-radius: 50%;
    }
}

/* light-header res end */



.header-row-common .header-right{
    display: flex;
    justify-content: center;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
}
.profile-menu .profile-menu-option{
    color: whitesmoke;
}
.no-pointer-cursor{
    pointer-events: none;
}
.profile-icon-main .profile-icon{
    height: 50px;
    width: 50px;
    color: whitesmoke;
}
.profile-menu .MuiMenu-paper{
background-color: #240736;
border-radius: 10px;
border: 0.7px solid gray;
}

/* .profile-menu .MuiPaper-rounded{
    top: 40px !important;
    left: 1275px !important;
} */

.profile-menu .MuiPaper-rounded ul{
 padding:4px 0px ;
}