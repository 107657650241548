.tokenomics-one-main {
    padding: 150px 85px;
    background-color: #240736;
    position: relative;
    overflow: hidden;
}
.tokenomics-one-main:before {
    background: linear-gradient(174deg, #d91ffb, #00ee92);
    filter: blur(104px);
    height: 450px;
    right: 0;
    bottom: 0;
    width: 431px;
    content: '';
    position: absolute;
    opacity: 0.3;
}
.tokenomics-one-main::after {
    background: linear-gradient(174deg, #d91ffb, #00ee92);
    filter: blur(104px);
    height: 450px;
    left: 0;
    top: 0;
    width: 431px;
    content: '';
    position: absolute;
    opacity: 0.3;
}
.one-tokenomics-row {
    margin-left: -15px;
    margin-right: -15px;
    align-items: center;
}

.one-tokenomics-row>* {
    padding: 0px 15px;
}

.one-tokenomics-title .title-gradient {
    font-size: 52px;
    line-height: 60px;
    font-family: 'poppins-bold';
    margin-bottom: 60px;
    text-transform: capitalize;
}

.one-tokenomics-left .one-tokenomics-para {
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    font-family: 'Poppins-Regular';
    color: var(--main-primary);
    margin: 0px 0 40px 0;
    display: -webkit-box;
    /* -webkit-line-clamp: 9; */
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 225px;
}

.one-tokenomicsimg-img-sec {
    text-align: right;
}

.one-tokenomicsimg-img {
    animation: imgBounce 5s linear infinite;
    width: 507px;
}

@keyframes imgBounce {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0px);
    }
}



@media screen and (min-width: 1200px) and (max-width: 1299px) {}

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {

    .tokenomics-one-main {
        padding: 80px 45px;
    }

    .one-tokenomics-title .title-gradient {
        font-size: 50px;
        line-height: 55px;
        margin-bottom: 50px;
    }

    .one-tokenomicsimg-img {
        width: 100%;
    }
}

/* phone and tablets */
@media screen and (max-width: 991px) {
    .one-tokenomicsimg-img {
        width: 100%;
    }
}

/* tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {
    .tokenomics-one-main {
        padding: 50px 0px;
    }
    
}

/* phone */
@media (max-width: 767px) {
    .tokenomics-one-main {
        padding: 50px 0px;
    }
 

}

@media screen and (min-width: 320px) and (max-width:374px) {


}