.tokenomics-two-main {
    padding: 150px 85px;
    background-color: #240736;
    position: relative;
    overflow: hidden;
}

.apexcharts-toolbar {
    display: none !important;
}

  

.two-tokenomics-row {
    margin-left: -15px;
    margin-right: -15px;
    align-items: center;
}

.two-tokenomics-row>* {
    padding: 0px 15px;
}

.two-tokenomics-title .title-gradient {
    font-size: 52px;
    line-height: 60px;
    font-family: 'poppins-bold';
    margin-bottom: 45.58px;
    text-transform: capitalize;
    text-transform: capitalize;
}

.two-tokenomicsimg-img-sec {
    text-align: center;
    position: relative;
}

.two-tokenomicsimg-img-sec .supply-text {
    font-size: 22px;
    font-family: 'Poppins-Regular';
    color: var(--main-primary);
}

.two-tokenomicsimg-img-sec .two-tokenomics-img-text {
    font-size: 36px;
    line-height: 29px;
    font-family: 'Poppins-Regular';
    color: var(--main-white) !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.two-tokenomicsimg-img{
    width: 445px;
}
.two-tokenomics-main-ul .two-tokenomics-main-li {
    background: #FFFFFF1A 0% 0% no-repeat padding-box;
    border-radius: 10px;
    min-height: 100px;
    margin: 0 0 30px 0;
    padding: 20px 25px;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
}
.two-tokenomics-main-ul .two-tokenomics-main-li:last-child{
    margin-bottom: 0px;
}
.two-tokenomics-main-ul .two-tokenomics-main-li:nth-child(1) {
    border-left: 10px solid #00ee92;
}

.two-tokenomics-main-ul .two-tokenomics-main-li:nth-child(2) {
    border-left: 10px solid #8247E5;
}

.two-tokenomics-main-ul .two-tokenomics-main-li:nth-child(3) {
    border-left: 10px solid #d91ffb;
}

.two-tokenomics-main-ul .two-tokenomics-main-li:nth-child(4) {
    border-left: 10px solid #fff;
}
.two-tokenomics-main-box{
    width: 100%;
}
.two-tokenomics-ammount{
    display: flex;
    justify-content: space-between;
    gap: 25px;
    margin-bottom: 5px;
    
}
.two-tokenomics-ammount .list-title, .two-tokenomics-ammount .list-amount-text{
    color: var(--main-primary);
    font-size: 20px;
    line-height: 23px;
    font-family: 'Poppins-SemiBold';
    display: -webkit-box;
      /* -webkit-line-clamp: 1; */
      -webkit-box-orient: vertical;
      overflow: hidden;
}
.two-tokenomics-main-ul .two-tokenomics-main-li:nth-child(1) .list-percent{
    color: #00ee92;
}
.two-tokenomics-main-ul .two-tokenomics-main-li:nth-child(2) .list-percent{
    color: #8247E5;
}
.two-tokenomics-main-ul .two-tokenomics-main-li:nth-child(3) .list-percent{
    color: #d91ffb;
}
.two-tokenomics-main-ul .two-tokenomics-main-li:nth-child(4) .list-percent{
    color: #fff;
}
.two-tokenomics-ammount .list-content{
    font-size: 20px;
    line-height: 23px;
    font-family: 'Poppins-Regular';
    color: var(--main-white);
    margin-top: 8px;
    display: -webkit-box;
      /* -webkit-line-clamp: 1; */
      -webkit-box-orient: vertical;
      overflow: hidden;
}
/* .two-tokenomicsimg-img{
    margin-left: 14px;
} */
.tokenomics-two-main::before{
content: '';
width: 219px;
height: 219px;
position: absolute;
top: 6%;
left: 30%;
background: transparent radial-gradient(closest-side at 50% 50%, rgb(130 71 229 / 25%) 0%, #8247e540 56%, #80808000 100%) 0% 0% no-repeat padding-box;
filter: blur(50px);
}
.tokenomics-two-main::after{
    content: '';
    width: 575px;
    height: 575px;
    position: absolute;
    top: 10%;
    right: -19%;
    background: transparent radial-gradient(closest-side at 50% 50%, rgb(130 71 229 / 25%) 0%, #8247e540 56%, #80808000 100%) 0% 0% no-repeat padding-box;
    filter: blur(50px);
}
.two-tokenomicsimg-img-sec{
    animation: imgBounce 5s linear infinite;
}
@keyframes imgBounce {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(-20px);
	}
	100% {
		transform: translateY(0px);
	}
}


@media screen and (min-width: 1200px) and (max-width: 1299px) {}

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {
    
    .tokenomics-two-main {
        padding: 80px 0px;
    }
    .two-tokenomics-title .title-gradient {
        font-size: 50px;
        line-height: 55px;
        margin-bottom: 50px;
    }
   .one-tokenomicsimg-img {
        width: 100%;
    }
}

/* phone and tablets */
@media screen and (max-width: 991px) {
   .two-tokenomicsimg-img{
        width: 100%;
    }
}

/* tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {
    
    .two-tokenomics-title .title-gradient {
        font-size: 40px;
        line-height: 42px;
        margin-bottom: 30px;
    }
    .tokenomics-two-main {
        padding: 80px 0px;
    }
    .two-tokenomicsimg-img-sec .two-tokenomics-img-text {
        font-size: 30px;
        line-height: 30px;
    }
    .two-tokenomicsimg-img-sec .supply-text {
        font-size: 20px;
    }
    .two-tokenomics-main-ul .two-tokenomics-main-li {
        padding: 15px 15px;
        margin: 0 0 15px 0;
    }
    .two-tokenomics-ammount .list-title, .two-tokenomics-ammount .list-amount-text {
        font-size: 15px;
        line-height: 18px;
    }
    .two-tokenomics-ammount .list-content {
        font-size: 15px;
        line-height: 19px;
    }

}

/* phone */
@media (max-width: 767px) {
    .tokenomics-two-main {
        padding: 50px 0px;
    }
    .two-tokenomics-title .title-gradient {
        font-size: 30px;
        line-height: 32px;
        margin-bottom: 40px;
    }

    .two-tokenomicsimg-img-sec{
        margin-top: 50px;
    }
    .two-tokenomics-ammount .list-title, .two-tokenomics-ammount .list-amount-text {
        font-size: 15px;
        line-height: 18px;
    }
    .two-tokenomics-ammount .list-content {
        font-size: 15px;
        line-height: 18px;
        margin-top: 5px;
    }
    .two-tokenomics-main-ul .two-tokenomics-main-li {
        padding: 10px 10px;
    }
    .two-tokenomicsimg-img-sec .two-tokenomics-img-text {
        font-size: 30px;
        line-height: 27px;
    }
    .two-tokenomicsimg-img-sec .supply-text {
        font-size: 19px;
    }
}
@media screen and (min-width: 320px) and (max-width:374px) {
    .two-tokenomicsimg-img-sec .supply-text {
        font-size: 15px;
    }
    .two-tokenomicsimg-img-sec .two-tokenomics-img-text {
        font-size: 22px;
        line-height: 21px;
    }
}